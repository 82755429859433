import { getFullnodeUrl, SuiClient } from '@mysten/sui/client';

export const getSuiBalance = async (
  account: string,
  provider: SuiClient,
  currencyAddress?: string,
  currencyDecimals?: number
) => {
  if (!provider || !currencyAddress || !currencyDecimals) {
    return '0';
  }

  const client = new SuiClient({ url: getFullnodeUrl('mainnet') });

  const balances = await client.getAllBalances({
    owner: account,
  });

  console.log('balances:', balances);

  const balance = balances.find(b => b.coinType.includes(currencyAddress));
  if (!balance) {
    return '0';
  }

  return (+balance.totalBalance / 10 ** currencyDecimals).toString();
};
