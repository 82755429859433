import { useBitcoinWallet } from '@/bitcoin/hooks/useBitcoinWallet';
import { OptionsProps } from '..';

export function BitcoinOptions({ onOpenChange }: OptionsProps) {
  const { wallets } = useBitcoinWallet();

  return (
    <div className="connect-wallet--group">
      <div className="connect-wallet--group-heading">Bitcoin wallets</div>
      <div className="connect-wallet--group-grid">
        {wallets.map((w, i) => (
          <button
            key={w.name}
            onClick={async () => {
              await w.connect();
              onOpenChange(false);
            }}
            className="connect-wallet--group-item"
          >
            <img
              src={w.icon}
              style={{ height: '24px', width: '24px' }}
              alt=""
            />
            {w.name}
          </button>
        ))}
      </div>
    </div>
  );
}
