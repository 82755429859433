import { AmountBySymbolOptions } from '@/types/interfaces';

export const maxDecimals = 256;
export const maxPlatformDecimals = 6;

export const amountOptionsForLimits: AmountBySymbolOptions = {
  stableCoins: 2,
  eth: 4,
  btc: 9,
  sol: 2,
  ton: 2,
  ape: 3,
  useCeil: true,
};
