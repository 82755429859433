import { ArrowDownIcon } from '@/Assets/Icons/arrow-down';
import { ArrowUpIcon } from '@/Assets/Icons/arrow-up';
import { PowerIcon } from '@/Assets/Icons/Power';
import { LoaderSmall } from '@/components/LoaderSmall';
import { useConnectedIcon } from '@/hooks/useConnectedIcon';
import { useResizeObserver } from '@/hooks/useResizeObserver';
import { useTonConnectedIcon } from '@/hooks/useTonConnectedIcon';
import { useAdvancedProgressStore } from '@/pages/ProgressAdvancedPage/store';
import { useAdvancedWalletStore } from '@/stores/advanced.store';
import {
  NetworkTypes,
  useWalletConnectInteractor,
} from '@/providers/web3Provider';
import { getSolanaWalletIcon } from '@/solana/getSolanaWalletIcon';
import { useStarknetAccount } from '@/starknet/hooks/account';
import { useStarknetConnectedIcon } from '@/starknet/hooks/useConnectedIcon';
import { useAppStore } from '@/stores/app.store';
import { useENSStore } from '@/stores/ens.store';
import { useWalletStore } from '@/stores/wallet.store';
import {
  PopoverContent,
  PopoverPortal,
  PopoverTrigger,
  Root,
} from '@radix-ui/react-popover';
import { useWallet as useSolanaWallet } from '@solana/wallet-adapter-react';
import { useTonAddress, useTonConnectUI } from '@tonconnect/ui-react';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDisconnect, useAccount as useEVMAccount } from 'wagmi';
import {
  useDisconnect as useFuelDisconnect,
  useWallet as useFuelWallet,
} from '@fuels/react';
import { useShallow } from 'zustand/react/shallow';
import { useWallet as useTronWallet } from '@tronweb3/tronwallet-adapter-react-hooks';
import { useConnectedTronIcon } from '@/tron/hooks/useConnectedTronIcon';
import '../../../styles.css';
import './styles.css';
import { formatAddress } from '@/utils';
import { useConnectedFuelIcon } from '@/fuel/hooks/useConnectedFuelIcon';
import { useSuiWallet } from '@/sui/hooks/useSuiWallet';
import { useBitcoinWallet } from '@/bitcoin/hooks/useBitcoinWallet';

export function WalletPopover() {
  const [isOpen, setIsOpen] = useState(false);

  // Addresses
  const { address: EVMAddress } = useEVMAccount();
  const { address: starknetAddress } = useStarknetAccount();
  const {
    publicKey: solanaAddress,
    disconnect: disconnectSolanaWallet,
    wallet: solanaWallet,
  } = useSolanaWallet();
  const tonAddress = useTonAddress();
  const { address: tronAddress } = useTronWallet();
  const { wallet: fuelAccount } = useFuelWallet();
  const {
    address: suiAddress,
    icon: suiIcon,
    disconnect: disconnectSuiWallet,
  } = useSuiWallet();
  const { disconnectAsync: disconnectFuelWallet } = useFuelDisconnect();

  // Icons
  const EVMIcon = useConnectedIcon();
  const tonIcon = useTonConnectedIcon();
  const starknetIcon = useStarknetConnectedIcon();
  const solanaIcon = getSolanaWalletIcon(
    solanaWallet?.adapter.name || '',
    solanaWallet?.adapter.icon || ''
  );
  const tronIcon = useConnectedTronIcon();
  const fuelIcon = useConnectedFuelIcon();

  const {
    icon: bitcoinIcon,
    address: bitcoinAddress,
    disconnect: disconnectBitcoin,
  } = useBitcoinWallet();

  const { width: addressWidth } = useResizeObserver({
    refId: 'wallet-popover--address',
  });

  const [networkFromName, isAdvancedOpen] = useAppStore(
    useShallow(s => [s.networkFrom?.name, s.isAdvancedOpen])
  );
  const [advancedNetworkFromName] = useAdvancedWalletStore(s => [
    s.networkFrom?.name,
  ]);
  const { pathname } = useLocation();
  const { currentOrder } = useWalletStore();
  const [advancedCurrentOrder] = useAdvancedProgressStore(s => [
    s.currentOrder,
  ]);

  const networkName = useMemo(() => {
    let networkName = !isAdvancedOpen
      ? networkFromName
      : advancedNetworkFromName;

    if (
      currentOrder?.currency_in.contract.network.name &&
      pathname.startsWith('/progress')
    ) {
      networkName = currentOrder?.currency_in.contract.network.name;
    }

    if (
      advancedCurrentOrder?.order.currency_in.contract.network.name &&
      pathname.startsWith('/progress')
    ) {
      networkName =
        advancedCurrentOrder.order.currency_in.contract.network.name;
    }

    return networkName;
  }, [
    isAdvancedOpen,
    networkFromName,
    advancedNetworkFromName,
    currentOrder?.currency_in.contract.network.name,
    advancedCurrentOrder?.order.currency_in.contract.network.name,
    pathname,
  ]);

  const [isLoading, defaultDomain, allENSDomains] = useENSStore(
    useShallow(s => [s.isLoading, s.defaultDomain, s.domains])
  );

  const [setDefaultDomain] = useENSStore(useShallow(s => [s.setDefaultDomain]));

  const domains = useMemo(() => {
    if (!networkName) return [];

    if (isAdvancedOpen) {
      return allENSDomains[networkName] ?? [];
    }

    return allENSDomains[networkName] ?? [];
  }, [allENSDomains, networkName, isAdvancedOpen]);

  const selectedDomain = useMemo(() => {
    if (!networkName) return domains[0];

    return defaultDomain[networkName] ?? domains[0];
  }, [defaultDomain, domains, networkName]);

  // Disconnect functions
  const { handleDeactivate } = useWalletConnectInteractor();
  const { disconnect: disconnectEvmWallet } = useDisconnect();
  const [tonConnectUI] = useTonConnectUI();
  const { disconnect: disconnectTronWallet } = useTronWallet();

  const walletAddress =
    EVMAddress ||
    starknetAddress ||
    tonAddress ||
    tronAddress ||
    bitcoinAddress ||
    suiAddress;

  return (
    <Root open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <button className="wallet-popover--trigger">
          <div className="d-flex align-items-center">
            {EVMAddress && (
              <div
                className="wallet-popover--trigger-icon"
                style={{
                  zIndex: 7,
                }}
              >
                <img src={EVMIcon} alt="" />
              </div>
            )}
            {starknetAddress && (
              <div
                className="wallet-popover--trigger-icon"
                style={{
                  zIndex: 6,
                }}
              >
                <img src={starknetIcon} alt="" />
              </div>
            )}
            {tonAddress && (
              <div
                className="wallet-popover--trigger-icon"
                style={{
                  zIndex: 5,
                }}
              >
                <img src={tonIcon} alt="" />
              </div>
            )}
            {solanaAddress && (
              <div
                className="wallet-popover--trigger-icon"
                style={{
                  zIndex: 4,
                }}
              >
                <img src={solanaIcon} alt="" />
              </div>
            )}
            {tronAddress && (
              <div
                className="wallet-popover--trigger-icon"
                style={{
                  zIndex: 3,
                }}
              >
                <img src={tronIcon} alt="" />
              </div>
            )}
            {fuelAccount?.address && (
              <div
                className="wallet-popover--trigger-icon"
                style={{
                  zIndex: 2,
                }}
              >
                <img src={fuelIcon} alt="" />
              </div>
            )}
            {bitcoinAddress && (
              <div
                className="wallet-popover--trigger-icon"
                style={{
                  zIndex: 1,
                }}
              >
                <img src={bitcoinIcon} alt="" />
              </div>
            )}
            {suiAddress && (
              <div
                className="wallet-popover--trigger-icon"
                style={{
                  zIndex: 0,
                }}
              >
                <img src={suiIcon} alt="" />
              </div>
            )}
          </div>
          <div className="d-none d-lg-flex align-items-center gap-2">
            <div className="text-start">
              <div
                id="wallet-popover--address"
                style={{ width: 'max-content' }}
              >
                {walletAddress && formatAddress(walletAddress, 4, 3)}
              </div>
              <AnimatePresence>
                {selectedDomain && (
                  <motion.div
                    initial={{ opacity: 0, height: 0, width: 0 }}
                    animate={{ opacity: 1, height: 'auto', width: 'auto' }}
                    exit={{ opacity: 0, height: 0, width: 0 }}
                    transition={{
                      duration: 0.3,
                    }}
                    style={{ minWidth: addressWidth, maxWidth: 160 }}
                    className="fw-medium wallet-popover--disabled overflow-hidden text-truncate"
                  >
                    {selectedDomain}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
            {isOpen && <ArrowUpIcon width={18} height={18} />}
            {!isOpen && <ArrowDownIcon width={18} height={18} />}
          </div>
        </button>
      </PopoverTrigger>
      <AnimatePresence key={networkName}>
        {isOpen && (
          <PopoverPortal forceMount>
            <PopoverContent
              hideWhenDetached
              align="end"
              sideOffset={17}
              avoidCollisions={false}
              autoFocus={false}
              onOpenAutoFocus={e => e.preventDefault()}
              asChild
            >
              <motion.div
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.9 }}
                className="wallet-popover--menu"
              >
                {EVMAddress && (
                  <div className="wallet-popover--menu-item">
                    <div className="wallet-popover--menu-item--icon">
                      <img src={EVMIcon} alt="" />
                    </div>
                    <div>
                      <div className="wallet-popover--menu-item--label">
                        EVM wallet
                      </div>
                      <div className="wallet-popover--menu-item--address">
                        {formatAddress(EVMAddress, 6, 4)}
                      </div>
                    </div>
                    <button
                      className="wallet-popover--menu-item--action"
                      onClick={() => disconnectEvmWallet()}
                    >
                      <PowerIcon />
                    </button>
                  </div>
                )}
                {isLoading && EVMAddress && (
                  <div className="d-flex justify-content-center mt-3">
                    <LoaderSmall width={16} height={16} />
                  </div>
                )}
                {!isLoading &&
                  EVMAddress &&
                  networkName &&
                  domains.length > 0 && (
                    <table className="wallet-popover--domains">
                      <thead>
                        <tr>
                          <th className="wallet-popover--domains-no"></th>
                          <th className="wallet-popover--domains-address">
                            Domain
                          </th>
                          <th className="wallet-popover--domains-action">
                            Default
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {domains.map((domain, i) => (
                          <tr
                            key={i}
                            onClick={() =>
                              setDefaultDomain(networkName, domain)
                            }
                          >
                            <th className="wallet-popover--domains-no">
                              {i + 1}
                            </th>
                            <td className="wallet-popover--domains-address text-truncate">
                              {domain}
                            </td>
                            <td className="wallet-popover--domains-action">
                              <div className="d-flex justify-content-end">
                                <div
                                  className={clsx(
                                    'wallet-popover--domains-indicator',
                                    selectedDomain === domain && 'active'
                                  )}
                                />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                {starknetAddress && (
                  <div className="wallet-popover--menu-item">
                    <div className="wallet-popover--menu-item--icon">
                      <img src={starknetIcon} alt="" />
                    </div>
                    <div>
                      <div className="wallet-popover--menu-item--label">
                        Starknet wallet
                      </div>
                      <div className="wallet-popover--menu-item--address">
                        {formatAddress(starknetAddress, 6, 4)}
                      </div>
                    </div>
                    <button
                      className="wallet-popover--menu-item--action"
                      onClick={() => handleDeactivate(NetworkTypes.STARKNET)}
                    >
                      <PowerIcon />
                    </button>
                  </div>
                )}
                {tonAddress && (
                  <div className="wallet-popover--menu-item">
                    <div className="wallet-popover--menu-item--icon">
                      <img src={tonIcon} alt="" />
                    </div>
                    <div>
                      <div className="wallet-popover--menu-item--label">
                        TON wallet
                      </div>
                      <div className="wallet-popover--menu-item--address">
                        {formatAddress(tonAddress, 6, 4)}
                      </div>
                    </div>
                    <button
                      className="wallet-popover--menu-item--action"
                      onClick={() => tonConnectUI.disconnect()}
                    >
                      <PowerIcon />
                    </button>
                  </div>
                )}
                {solanaAddress && (
                  <div className="wallet-popover--menu-item">
                    <div className="wallet-popover--menu-item--icon">
                      <img src={solanaIcon} alt="" />
                    </div>
                    <div>
                      <div className="wallet-popover--menu-item--label">
                        Solana wallet
                      </div>
                      <div className="wallet-popover--menu-item--address">
                        {formatAddress(solanaAddress.toBase58(), 6, 4)}
                      </div>
                    </div>
                    <button
                      className="wallet-popover--menu-item--action"
                      onClick={disconnectSolanaWallet}
                    >
                      <PowerIcon />
                    </button>
                  </div>
                )}
                {tronAddress && (
                  <div className="wallet-popover--menu-item">
                    <div className="wallet-popover--menu-item--icon">
                      <img src={tronIcon} alt="" />
                    </div>
                    <div>
                      <div className="wallet-popover--menu-item--label">
                        Tron wallet
                      </div>
                      <div className="wallet-popover--menu-item--address">
                        {formatAddress(tronAddress, 6, 4)}
                      </div>
                    </div>
                    <button
                      className="wallet-popover--menu-item--action"
                      onClick={disconnectTronWallet}
                    >
                      <PowerIcon />
                    </button>
                  </div>
                )}
                {fuelAccount?.address && (
                  <div className="wallet-popover--menu-item">
                    <div className="wallet-popover--menu-item--icon">
                      <img src={fuelIcon} alt="" />
                    </div>
                    <div>
                      <div className="wallet-popover--menu-item--label">
                        Fuel wallet
                      </div>
                      <div className="wallet-popover--menu-item--address">
                        {formatAddress(fuelAccount?.address.toString(), 6, 4)}
                      </div>
                    </div>
                    <button
                      className="wallet-popover--menu-item--action"
                      onClick={() => disconnectFuelWallet()}
                    >
                      <PowerIcon />
                    </button>
                  </div>
                )}
                {bitcoinAddress && (
                  <div className="wallet-popover--menu-item">
                    <div className="wallet-popover--menu-item--icon">
                      <img src={bitcoinIcon} alt="" />
                    </div>
                    <div>
                      <div className="wallet-popover--menu-item--label">
                        Bitcoin wallet
                      </div>
                      <div className="wallet-popover--menu-item--address">
                        {formatAddress(bitcoinAddress.toString(), 6, 4)}
                      </div>
                    </div>

                    <button
                      className="wallet-popover--menu-item--action"
                      onClick={() => disconnectBitcoin()}
                    >
                      <PowerIcon />
                    </button>
                  </div>
                )}
                {suiAddress && (
                  <div className="wallet-popover--menu-item">
                    <div className="wallet-popover--menu-item--icon">
                      <img src={suiIcon} alt="" />
                    </div>
                    <div>
                      <div className="wallet-popover--menu-item--label">
                        Sui wallet
                      </div>
                      <div className="wallet-popover--menu-item--address">
                        {formatAddress(suiAddress?.toString(), 6, 4)}
                      </div>
                    </div>

                    <button
                      className="wallet-popover--menu-item--action"
                      onClick={() => disconnectSuiWallet()}
                    >
                      <PowerIcon />
                    </button>
                  </div>
                )}
              </motion.div>
            </PopoverContent>
          </PopoverPortal>
        )}
      </AnimatePresence>
    </Root>
  );
}
