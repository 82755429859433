import { Adventure, AdventureTask } from '@/types/phases';
import { create } from 'zustand';

interface QuestsStore {
  adventures: Adventure[];
  setAdventures: (adventures: Adventure[]) => void;
  addAdventure: (adventure: Adventure) => void;

  tasks: Record<number, AdventureTask[] | undefined>;
  setTasks: (adventureId: number, tasks: AdventureTask[]) => void;
  setTaskStatus: (adventureId: number, taskId: number, status: boolean) => void;
}

export const questsAdventuresStore = create<QuestsStore>((set, get) => ({
  adventures: [],
  setAdventures: adventures => set({ adventures }),
  addAdventure: adventure => {
    const isExist = get().adventures.find(j => j.id === adventure.id);

    if (isExist) {
      return set(state => ({
        adventures: state.adventures.map(j =>
          j.id === adventure.id ? { ...j, ...adventure } : j
        ),
      }));
    }

    set(state => ({ adventures: [...state.adventures, adventure] }));
  },

  tasks: {},
  setTasks: (adventureId, tasks) =>
    set(state => ({ tasks: { ...state.tasks, [adventureId]: tasks } })),

  setTaskStatus: (adventureId, taskId, status) =>
    set(state => ({
      tasks: {
        ...state.tasks,
        [adventureId]: state.tasks[adventureId]?.map(task => {
          if (task.id === taskId) {
            return { ...task, isCompleted: status };
          }

          return task;
        }),
      },
    })),
}));

export const useQuestsAdventuresStore = questsAdventuresStore;
