import { SVGAttributes } from 'react';

export function RotateIcon(props: SVGAttributes<SVGElement>) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.5 2V5H3.5"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1.755 7.49997C2.0792 8.42016 2.69367 9.21006 3.50583 9.75066C4.31799 10.2913 5.28384 10.5533 6.25786 10.4972C7.23187 10.4411 8.16129 10.0701 8.90606 9.43985C9.65084 8.80965 10.1706 7.95446 10.3871 7.00315C10.6036 6.05185 10.505 5.05595 10.1063 4.16552C9.70758 3.2751 9.03026 2.53837 8.17642 2.06636C7.32257 1.59434 6.33845 1.41261 5.37234 1.54853C4.40623 1.68446 3.51046 2.13068 2.82 2.81997L0.5 4.99997"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
