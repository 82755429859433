import { getFuelConnectorIcon } from '@/fuel/utils/getConnectorIcon';
import { useWallet as useSuiWallet } from '@suiet/wallet-kit';
import { OptionsProps } from '..';
import { getSuiConnectorIcon } from '@/sui/utils/getConnectorIcon';

export function SuiOptions({ onOpenChange }: OptionsProps) {
  const { configuredWallets, select } = useSuiWallet();

  return (
    <div className="connect-wallet--group">
      <div className="connect-wallet--group-heading">Sui wallets</div>
      <div className="connect-wallet--group-grid">
        {configuredWallets.map(connector => (
          <button
            key={connector.name}
            className="connect-wallet--group-item w-100"
            onClick={() => {
              if (!connector.installed) {
                window.open(connector.downloadUrl.browserExtension, '_blank');
                return;
              }
              select(connector.name);
              onOpenChange(false);
            }}
          >
            <div style={{ borderRadius: '50%', backgroundColor: 'white' }}>
              <img
                src={getSuiConnectorIcon(connector.name)}
                style={{
                  height: '24px',
                  width: '24px',
                  minWidth: '24px',
                  borderRadius: '50%',
                }}
                alt={connector.name}
              />
            </div>
            {connector.name}
          </button>
        ))}
      </div>
    </div>
  );
}
