import { useMemo, useState } from 'react';

import { Image } from '@/components/Image';
import { RotateIcon } from '@/Assets/Icons/Rotate';
import { mainnetNetworks } from '@/constants/mock_networks.constants';
import { PHASES_API_URL } from '@/Config/ApiConfig';
import { RetroBridgerPointsIcon } from '@/Assets/Icons/RetroBrdigePoints';
import { Adventure } from '@/types/phases';
import { ROUTES } from '@/constants/routes.constants';
import { PhaseIcons } from '@/pages/PhasePage/Icons';
import { phaseService } from '@/services/phaseApi';
import { useRetroPointsStore } from '@/stores/retroPoints.store';
import { useQuestsAdventuresStore } from '@/pages/quests/QuestsAdventures/store';

interface TaskItemProps {
  adventure: Adventure;
}

export function WeeklyTask({ adventure }: TaskItemProps) {
  const [isRefreshing, setIsRefreshing] = useState(false);
  const user = useRetroPointsStore(s => s.user);
  const tasks = useQuestsAdventuresStore(s => s.tasks[adventure.id]);
  const { backgroundSrc, capsuleImg } = useMemo(() => {
    const result = { backgroundSrc: '', capsuleImg: '' };
    const network = mainnetNetworks.find(n => n.name === adventure.network);
    result.backgroundSrc = network?.task_bg || '';
    result.capsuleImg = network?.capsuleImg || '';

    return result;
  }, [adventure]);

  const isCompleted = tasks ? tasks.every(t => t.isCompleted) : false;

  const handleBannerClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.preventDefault();
    window.location.href = ROUTES.QUESTS_PAGE_ADVENTURES;
  };

  const handleRefreshClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    if (isRefreshing) return;
    setIsRefreshing(true);
    setTimeout(() => setIsRefreshing(false), 1500);
    if (user && user.evm_address) {
      phaseService.refreshAdventureStatus(adventure, user.evm_address);
    }
  };

  return (
    <div
      className="notifications-menu--task"
      style={{
        backgroundImage: `url(${backgroundSrc})`,
      }}
      onClick={handleBannerClick}
    >
      <div className="notifications-menu--task--content">
        <div className="notifications-menu--task--heading">
          <span>Complete</span>
          <span>
            <Image
              src={PHASES_API_URL + adventure?.icon}
              width={16}
              height={16}
            />
            {adventure.network}
          </span>
          <span>Adventure and earn rewards</span>
        </div>
        <div className="notifications-menu--task--rewards">
          {adventure.reward.multiplier && (
            <div className="notifications-menu--task--rewards--item">
              <span style={{ color: '#bf97ff' }}>x</span>{' '}
              {adventure.reward.multiplier} multiplier
            </div>
          )}

          {adventure.reward.points && (
            <>
              <span>+</span>
              <div className="notifications-menu--task--rewards--item">
                <RetroBridgerPointsIcon width={21} /> {adventure.reward.points}
              </div>
            </>
          )}
        </div>
      </div>

      {capsuleImg && (
        <div className="notifications-menu--task--capsule-img">
          <Image width={123.33} height={111} src={capsuleImg} />
        </div>
      )}

      {isCompleted && (
        <div className="notifications-menu--task--action">
          <PhaseIcons.Done width={28} height={28} />
        </div>
      )}
      {!isCompleted && (
        <button
          className="notifications-menu--task--action"
          onClick={handleRefreshClick}
        >
          <RotateIcon className={isRefreshing ? 'rotate-animation' : ''} />
        </button>
      )}
    </div>
  );
}
