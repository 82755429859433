import { useMemo, useState } from 'react';

import { Image } from '@/components/Image';
import { RotateIcon } from '@/Assets/Icons/Rotate';
import { mainnetNetworks } from '@/constants/mock_networks.constants';
import { PHASES_API_URL } from '@/Config/ApiConfig';
import { RetroBridgerPointsIcon } from '@/Assets/Icons/RetroBrdigePoints';
import { AdventureTask, PhaseTask } from '@/types/phases';
import GameTaskBg from '@/Assets/images/tasks/plain-bg/game.png';
import { retroPointsService } from '@/services/retroPoints';
import { useRetroPointsStore } from '@/stores/retroPoints.store';
import { PhaseIcons } from '@/pages/PhasePage/Icons';
import { useSearchParams } from 'react-router-dom';
import { appStore, useAppStore } from '@/stores/app.store';
import { useNetworksStore } from '@/stores/networks.store';
import { INetwork } from '@/types/apiTypes';
import { ROUTES } from '@/constants/routes.constants';

interface DailyTaskProps {
  task: PhaseTask | AdventureTask;
}

export function DailyTask({ task }: DailyTaskProps) {
  const points = task.actionName === 'Play RetroBridger Game' ? 10 : 100;

  const [isRefreshing, setIsRefreshing] = useState(false);
  const user = useRetroPointsStore(s => s.user);
  const { backgroundSrc, dailyImg } = useMemo(() => {
    const result = { backgroundSrc: '', capsuleImg: '', dailyImg: '' };

    if (task && task.destinationNetwork) {
      const network = mainnetNetworks.find(
        n => n.name === task.destinationNetwork
      );
      result.backgroundSrc = network?.task_bg || '';
      result.dailyImg = network?.dailyTaskImg || '';
    } else if (task.actionName === 'Play RetroBridger Game') {
      result.backgroundSrc = GameTaskBg;
    }
    return result;
  }, [task]);

  const title = task.title.split('image');

  const networks = useNetworksStore(s => s.networks);
  const [setNetworkTo] = useAppStore(s => [s.setNetworkTo]);
  const setSearchParams = useSearchParams()[1];

  const currentNetwork = useMemo(() => {
    return networks.find(network => network.name === task.destinationNetwork);
  }, [networks, task]);

  const handleClick = () => {
    if (currentNetwork && currentNetwork.active) {
      handleNetworkToChange(currentNetwork);
    } else if (task.actionName === 'Play RetroBridger Game') {
      window.location.href = ROUTES.QUESTS_PAGE_ACTIVITIES;
    }
  };

  const handleNetworkToChange = (network: INetwork) => {
    const { networkFrom, networkTo, setNetworkFrom } = appStore.getState();
    const from = networkFrom;
    const to = networkTo;
    if (network?.id === from?.id) {
      if (to) {
        setSearchParams(searchParams => {
          searchParams.set('source', to.name);
          return searchParams;
        });
        setNetworkFrom(to);
      }
    }

    if (network && !!networkTo) {
      setSearchParams(searchParams => {
        searchParams.set('destination', network.name);
        return searchParams;
      });
    }

    setNetworkTo(network);
  };

  const handleRefreshClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    taskId: number,
    wallet: string
  ) => {
    e.stopPropagation();
    if (isRefreshing) return;
    setIsRefreshing(true);
    setTimeout(() => setIsRefreshing(false), 1500);
    retroPointsService.refreshDailyTaskStatus(taskId, wallet);
  };

  return (
    <div
      className="notifications-menu--task"
      style={{
        backgroundImage: `url(${backgroundSrc})`,
      }}
      onClick={handleClick}
    >
      <div className="notifications-menu--task--content">
        <div className="notifications-menu--task--heading">
          <span>{title[0]}</span>
          <span>
            <Image
              src={PHASES_API_URL + (task.images?.[0] || '')}
              width={16}
              height={16}
            />
            {task.destinationNetwork || 'Retro Points'}
          </span>
          <span>
            {title[1].replace(task.destinationNetwork || 'Retro Points', '')}
          </span>
        </div>
        <div className="notifications-menu--task--rewards">
          <div className="notifications-menu--task--rewards--item">
            <RetroBridgerPointsIcon width={21} /> {points}
          </div>
        </div>
      </div>

      {dailyImg && (
        <div className="notifications-menu--task--coins-img">
          <Image src={dailyImg} />
        </div>
      )}

      {task.isCompleted && (
        <div className="notifications-menu--task--action">
          <PhaseIcons.Done width={28} height={28} />
        </div>
      )}
      {!task.isCompleted && (
        <button
          className="notifications-menu--task--action"
          onClick={e => handleRefreshClick(e, task.id, user?.evm_address || '')}
        >
          <RotateIcon className={isRefreshing ? 'rotate-animation' : ''} />
        </button>
      )}
    </div>
  );
}
