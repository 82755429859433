import { useMemo } from 'react';
import { SuiClient } from '@mysten/sui/client';
import { Transaction, coinWithBalance } from '@mysten/sui/transactions';
import { useSuiClient, useWallet } from '@suiet/wallet-kit';
import { getSuiConnectorIcon } from '../utils/getConnectorIcon';
import { SUI_NATIVE_TOKEN } from '../constants';

export const useSuiWallet = () => {
  const {
    address,
    adapter,
    disconnect: disconnectSui,
    signPersonalMessage,
    chain,
    signAndExecuteTransaction,
  } = useWallet();

  const suiClient = useSuiClient() as SuiClient;

  const icon = useMemo(
    () => getSuiConnectorIcon(adapter?.name),
    [adapter?.name]
  );

  const signMessage = async (message: string) => {
    if (!address) {
      throw new Error('Current wallet is not authorized for this connection!');
    }
    const msgBytes = new TextEncoder().encode(message);
    const signedMessage = await signPersonalMessage({
      message: msgBytes,
    });
    return signedMessage.signature;
  };

  /**
   * @see https://sdk.mystenlabs.com/typescript/transaction-building/basics
   */
  const sendTransaction = async (
    destination: string,
    amount: number,
    assetId?: string
  ) => {
    try {
      if (!address)
        throw new Error(
          'Current wallet is not authorized for this connection!'
        );

      const tx = new Transaction();
      tx.setSender(address);

      if (assetId === SUI_NATIVE_TOKEN) {
        const [coin] = tx.splitCoins(tx.gas, [amount]);
        tx.transferObjects([coin], destination);
      } else {
        tx.transferObjects(
          [
            coinWithBalance({
              balance: amount,
              type: assetId,
              useGasCoin: true,
            }),
          ],
          destination
        );
      }
      await tx.build({ client: suiClient });
      const res = await signAndExecuteTransaction({
        transaction: tx,
      });
      return res;
    } catch (error) {
      console.log('ERROR ON SUI SEND TX', error);
      throw error;
    }
  };

  return {
    wallet: suiClient,
    address: address,
    chain: chain,
    icon,
    signMessage,
    sendTransaction,
    disconnect: disconnectSui,
  };
};
