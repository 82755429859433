import ExplorerMobileBg from '@/Assets/images/explorer-mobile-bg.svg';
import SendBg from '@/Assets/images/send-bg-png.png';
import { ConnectWalletModalWithDisabled } from '@/components/ConnectWalletModalWithDisabled';
import Footer from '@/components/Footer';
import { Header } from '@/components/Header';
import { Loader } from '@/components/Loader';
import { api } from '@/services/api';
import { useStarknetAccount } from '@/starknet/hooks/account';
import { useWalletStore } from '@/stores/wallet.store';
import { OrderSearchStatuses } from '@/types/enums';
import { useWallet as useSolanaWallet } from '@solana/wallet-adapter-react';
import { useTonAddress } from '@tonconnect/ui-react';
import { useWallet as useTronWallet } from '@tronweb3/tronwallet-adapter-react-hooks';
import { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import { Helmet } from 'react-helmet';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDebounceValue } from 'usehooks-ts';
import { useAccount as useEVMAccount } from 'wagmi';
import './Explorer.css';
import { ConnectWalletPlaceHolder } from './components/ConnectWalletPlaceholder';
import { ConnectedWalletPopover } from './components/ConnectedWalletPopover';
import EmptyTablePlaceHolder from './components/EmptyTablePlaceholder';
import { SearchBar } from './components/SearchBar';
import { ExplorerTable } from './components/Table';
import { Address } from '@ton/core';
import { useAccount as useBitcoinAccount } from '@gobob/sats-wagmi';
import { useFuelWallet } from '@/fuel/hooks/useFuelWallet';
import { useSuiWallet } from '@/sui/hooks/useSuiWallet';

export const ORDERS_PER_PAGE = 50;

const ExplorerPage = () => {
  const { address: EVMAddress } = useEVMAccount();
  const { address: starknetAddress } = useStarknetAccount();
  const { publicKey: solanaAddress } = useSolanaWallet();
  const tonAddress = useTonAddress();
  const { address: tronAddress } = useTronWallet();
  const { address: bitcoinAddress } = useBitcoinAccount();
  const { address: fuelAddress } = useFuelWallet();
  const { address: suiAddress } = useSuiWallet();

  const { orders, setOrders } = useWalletStore();
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState<OrderSearchStatuses>(
    OrderSearchStatuses.ALL
  );
  const [isConnectWalletOpen, setIsConnectWalletOpen] = useState(false);
  const [searchDebounce] = useDebounceValue(search, 300);
  const [page, setPage] = useState(0);

  useEffect(() => {
    setLoading(true);
    setPage(0);

    const wallets = [
      EVMAddress!,
      starknetAddress!,
      tonAddress ? Address.parse(tonAddress).toRawString() : tonAddress!,
      solanaAddress?.toBase58(),
      tronAddress!,
      fuelAddress!,
      bitcoinAddress!,
      suiAddress!,
    ].filter(el => !!el);

    if (wallets.length <= 0) return;

    api
      .getOrders(status, searchDebounce, wallets, true, 0, ORDERS_PER_PAGE)
      .finally(() => setLoading(false));
  }, [
    status,
    searchDebounce,
    EVMAddress,
    solanaAddress,
    starknetAddress,
    tonAddress,
    tronAddress,
    bitcoinAddress,
    fuelAddress,
    suiAddress,
  ]);

  useEffect(() => {
    const interval = setInterval(
      () => {
        api.getOrders(
          status,
          searchDebounce,
          [
            EVMAddress!,
            starknetAddress!,
            tonAddress ? Address.parse(tonAddress).toRawString() : tonAddress!,
            solanaAddress?.toBase58(),
            tronAddress!,
            fuelAddress!,
            bitcoinAddress!,
            suiAddress!,
          ].filter(el => !!el),
          true,
          0,
          ORDERS_PER_PAGE * (page + 1)
        );
      },
      page >= 2 ? 15000 : 5000
    );
    return () => clearInterval(interval);
  }, [
    status,
    searchDebounce,
    EVMAddress,
    starknetAddress,
    tonAddress,
    solanaAddress,
    tronAddress,
    bitcoinAddress,
    fuelAddress,
    suiAddress,
    page,
  ]);

  const shouldShowConnectWallet =
    !EVMAddress &&
    !starknetAddress &&
    !tonAddress &&
    !solanaAddress &&
    !tronAddress &&
    !bitcoinAddress &&
    !suiAddress &&
    !fuelAddress;

  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    if (status !== OrderSearchStatuses.IN_PROGRESS) {
      setHasMore(false);
      return;
    }
    setHasMore(orders.length < 200);
  }, [orders.length, status]);

  const loadMore = async () => {
    setPage(page + 1);

    const data = await api.getOrders(
      status,
      searchDebounce,
      [
        EVMAddress!,
        starknetAddress!,
        tonAddress ? Address.parse(tonAddress).toRawString() : tonAddress!,
        solanaAddress?.toBase58(),
        tronAddress!,
        bitcoinAddress!,
        fuelAddress!,
        suiAddress!,
      ].filter(el => !!el),
      false,
      (page + 1) * ORDERS_PER_PAGE,
      ORDERS_PER_PAGE
    );

    if (!data) return;

    setOrders([...orders, ...data]);
  };

  return (
    <div className="page-background-color">
      <Helmet>
        <title>Explorer | Retro Bridge</title>
      </Helmet>
      <Container
        fluid
        className={
          'explorerPageBg w-full exlorerContainer textTable d-flex  flex-1 page-padding-bottom flex-column align-items-center position-relative'
        }
      >
        <img src={SendBg} alt="" className="send-bg-img" draggable={false} />
        <img
          src={ExplorerMobileBg}
          alt=""
          className="send-bg-img-mobile"
          draggable={false}
        />
        <div className="explorer-header-container">
          <Header />
        </div>

        <div className="d-flex flex-column flex-md-row align-items-center w-100">
          <div className="explorerTitle d-flex">My Recent Transactions</div>
          <div className="me-auto me-md-0 ms-md-auto">
            <ConnectedWalletPopover />
          </div>
        </div>

        <SearchBar
          search={search}
          setSearch={setSearch}
          status={status}
          setStatus={setStatus}
        />

        {loading && !shouldShowConnectWallet && (
          <div style={{ height: 588 }}>
            <Loader />
          </div>
        )}

        {!loading && !orders.length && !shouldShowConnectWallet && (
          <div style={{ height: 588 }}>
            <EmptyTablePlaceHolder />
          </div>
        )}

        {shouldShowConnectWallet && (
          <div style={{ height: 588 }}>
            <ConnectWalletPlaceHolder setOpenModal={setIsConnectWalletOpen} />
          </div>
        )}

        {!loading && !!orders.length && !shouldShowConnectWallet && (
          <InfiniteScroll
            next={loadMore}
            hasMore={hasMore}
            scrollableTarget="explorer-viewport"
            dataLength={orders?.length ?? 0}
            loader={false}
          >
            <ExplorerTable orders={orders} />
          </InfiniteScroll>
        )}

        <div className="explorer-footer-container">
          <Footer />
        </div>
      </Container>
      <ConnectWalletModalWithDisabled
        setOpenModal={setIsConnectWalletOpen}
        openModal={isConnectWalletOpen}
        onlyDisconnectedNetworkTypes
      />
    </div>
  );
};

export default ExplorerPage;
