import { SVGAttributes } from 'react';

export function Clock({
  color = 'white',
  ...props
}: SVGAttributes<SVGElement>) {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.00008 13.3334C10.2217 13.3334 12.8334 10.7217 12.8334 7.50002C12.8334 4.27836 10.2217 1.66669 7.00008 1.66669C3.77842 1.66669 1.16675 4.27836 1.16675 7.50002C1.16675 10.7217 3.77842 13.3334 7.00008 13.3334Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 4V7.5L9.33333 8.66667"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
