import validate from 'bitcoin-address-validation';

export const isValidBitcoinAddress = (address?: string) => {
  try {
    if (!address) return false;
    return validate(address);
  } catch (error) {
    return false;
  }
};
