import {
  ElliWallet,
  EthosWallet,
  FrontierWallet,
  GlassWallet,
  MartianWallet,
  MorphisWallet,
  NightlyWallet,
  OneKeyWallet,
  SensuiWallet,
  SpacecyWallet,
  SuietWallet,
  SuiWallet,
  SurfWallet,
  TokenPocketWallet,
} from '@suiet/wallet-kit';

// TODO: Add icons for other connectors
export const SUI_ICONS: Record<string, string> = {
  [SuietWallet.name]: 'suiet',
  [SuiWallet.name]: 'sui',
  [EthosWallet.name]: 'ethos',
  [MartianWallet.name]: 'martian',
  [SurfWallet.name]: 'surf',
  [GlassWallet.name]: 'glass',
  [MorphisWallet.name]: 'morphis',
  [OneKeyWallet.name]: 'oneKey',
  [SpacecyWallet.name]: 'spacecy',
  [SensuiWallet.name]: 'sensui',
  [NightlyWallet.name]: 'nightly',
  [ElliWallet.name]: 'elli',
  [TokenPocketWallet.name]: 'tokenPocket',
  [FrontierWallet.name]: 'frontier',
};

export const getSuiConnectorIcon = (connector?: string) => {
  return connector && SUI_ICONS[connector]
    ? require(`@/Assets/images/wallets/${SUI_ICONS[connector]}.png`)
    : require('@/Assets/images/wallets/sui.png');
};
