import { InternalAxiosRequestConfig } from 'axios';
import { PHASES_API_URL } from '../Config/ApiConfig';
import { phaseBannerStore } from '../stores/phaseBanner.store';
import { WalletStore, walletStore } from '../stores/wallet.store';
import { INetwork } from '../types/apiTypes';
import {
  Adventure,
  AdventureTask,
  Journey,
  MintNFTResponse,
  Phase,
  PhaseTask,
} from '../types/phases';
import { logInGroup } from '../utils';
import { ApiService as Api } from './CoreApi';
import { retroPointsService } from './retroPoints';
import { questsPhasesStore } from '@/pages/quests/QuestsPhases/store';
import { phaseStore } from '@/pages/PhasePage/store';
import { questsAdventuresStore } from '@/pages/quests/QuestsAdventures/store';

const requestInterceptor = (config: InternalAxiosRequestConfig) => {
  return config;
};
export class PhaseService {
  private _api: Api;
  constructor() {
    this._api = new Api(PHASES_API_URL, {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    });

    this._api.instance.defaults.headers.put['Content-Type'] =
      'application/json';
    this._api.instance.interceptors.request.use(config =>
      requestInterceptor(config)
    );
  }

  async getAdventuresWithTasks(address: string): Promise<Adventure[] | null> {
    const url = '/api/journey/adventures';
    let { data } = await this._api.get<{ data: Adventure[] }>(url);

    data = data.sort((a, b) => {
      if (a.isWeeklyAdventure === b.isWeeklyAdventure) return 0;
      return a.isWeeklyAdventure ? -1 : 1;
    });
    const tasks: Record<number, AdventureTask[]> = {};

    await Promise.all(
      data.map(async adventure => {
        const adventureTasks: AdventureTask[] = (await this.getPhaseTasks(
          adventure.id,
          address
        )) as AdventureTask[];

        tasks[adventure.id] = adventureTasks;
      })
    );
    questsAdventuresStore.setState({ adventures: data, tasks });

    return data;
  }

  async getAdventureTasks(
    journeyId: number,
    wallet: string
  ): Promise<AdventureTask[] | null> {
    try {
      const url = '/api/task/tasks';
      const { data } = await this._api.get<{ data: AdventureTask[] }>(url, {
        params: {
          journeyId,
          wallet,
        },
      });

      const { setTasks } = questsAdventuresStore.getState();
      setTasks(journeyId, data);

      return data;
    } catch (error) {
      console.warn(error);
    }
    return null;
  }

  async refreshAdventureStatus(
    adventure: Adventure,
    wallet: string
  ): Promise<AdventureTask[] | null> {
    try {
      const url = '/api/task/refresh_result_task';
      const tasks = await Promise.all(
        adventure.tasks.map(async t => {
          const { data } = await this._api.get<{ data: PhaseTask }>(url, {
            params: {
              journeyId: adventure.id,
              taskId: t.id,
              wallet,
            },
          });
          return data as AdventureTask;
        })
      );

      const { setTasks } = questsAdventuresStore.getState();
      setTasks(adventure.id, tasks);

      return tasks;
    } catch (error) {
      console.warn(error);
    }
    return null;
  }

  async getJourneys(
    phase?: Phase,
    selectedJourneyIndex?: number,
    setStore = true
  ): Promise<Journey[] | null> {
    const url = '/api/journey/all';
    let { data } = await this._api.get<{ data: Journey[] }>(url, {
      params: {
        phase,
      },
    });

    data = data.sort(
      (a, b) =>
        new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
    );

    if (setStore) {
      const activeJourney =
        data.length === 1 ? data[0] : data.find(j => j.isActive);
      const selectedJourney =
        selectedJourneyIndex !== undefined
          ? data?.[+selectedJourneyIndex]
          : activeJourney;

      phaseStore.setState({
        journeys: data,
        activeTabId: selectedJourney?.id,
        selectedJourney: selectedJourney || null,
      });
    }

    return data;
  }

  async getPhaseTasks(
    journeyId: number,
    wallet?: string,
    setToStore = true
  ): Promise<PhaseTask[] | null> {
    try {
      const url = '/api/task/tasks';
      const { data } = await this._api.get<{ data: PhaseTask[] }>(url, {
        params: {
          journeyId,
          wallet: wallet?.length === 0 ? undefined : wallet,
        },
      });

      const { setTasks } = phaseStore.getState();

      setTasks(data, journeyId);

      return data;
    } catch (error) {
      console.warn(error);
    }
    return null;
  }

  async getPhaseTaskStatus(
    taskId: number,
    wallet: string,
    journeyId?: Journey['id']
  ): Promise<boolean | null> {
    try {
      const url = '/api/task/refresh_result_task';

      if (!journeyId) return null;

      let journey = questsPhasesStore
        .getState()
        .journeys.find(j => j.id === journeyId);

      if (!journey) {
        journey = phaseStore.getState().journeys.find(j => j.id === journeyId);
      }

      const { data } = await this._api.get<{ data: PhaseTask }>(url, {
        params: {
          journeyId: journeyId,
          taskId,
          wallet: wallet.length === 0 ? undefined : wallet,
        },
      });

      await phaseService.getClaimNFTStatus(
        wallet,
        undefined,
        journey?.phase,
        journey?.journey
      );

      await retroPointsService.userInfo();

      return data.isCompleted;
    } catch (error) {
      console.warn(error);
    }
    return null;
  }

  async getClaimNFTStatus(
    wallet: string,
    network?: string,
    phase?: Phase,
    journey?: string
  ): Promise<MintNFTResponse['data'] | null> {
    try {
      const url = '/api/task/can_user_claim_nft';

      const data = await this._api.get<MintNFTResponse>(url, {
        params: {
          phase: phase,
          wallet: wallet.length === 0 ? undefined : wallet,
          network: network ? network : undefined,
          journey: journey,
        },
      });

      return data.data;
    } catch (error) {
      console.warn(error);
    }
    return null;
  }

  async updateSocialTask(taskId: number, wallet: string) {
    try {
      const url = '/api/task/social_network_complete';
      const {
        journeys,
        setTaskStatus,
        setIsNFTEligible,
        setIsNFTClaimed,
        setNFTClaimedNetwork,
      } = phaseStore.getState();
      const activeJourney = journeys.find(journey => journey.isActive);

      if (!activeJourney) return;

      const { data } = await this._api.put<{ data: PhaseTask }>(
        url,
        {},
        {
          params: {
            journeyId: activeJourney.id,
            taskId,
            wallet,
          },
        }
      );

      if (!data) throw new Error('No data');

      setTaskStatus(taskId, data.isCompleted);

      const res = await phaseService.getClaimNFTStatus(wallet);

      if (!res) return data;

      setIsNFTEligible(res.eligible);
      setIsNFTClaimed(res.isClaimed);
      setNFTClaimedNetwork(res.network);

      return data;
    } catch (error) {
      console.warn(error);
    }
    return undefined;
  }

  async getAmountOfParticipants() {
    try {
      const url = `/api/journey/amount_of_participants/${
        phaseStore.getState().phase
      }`;
      const { data } = await this._api.get<{ data: { amount: number } }>(url);

      phaseStore.setState({ uniqueParticipants: data.amount });

      return data.amount;
    } catch (error) {
      console.warn(error);
    }
    return 0;
  }

  async getUserNFT(wallet: string, networkId: INetwork['id']) {
    try {
      const url = `/api/user_journey/user_nft/${wallet}/${networkId}`;
      const data = await this._api.get<WalletStore['NFTDiscount']>(url);

      walletStore.setState({ NFTDiscount: data });

      return data;
    } catch (error) {
      console.warn(error);
    }
    return null;
  }

  async estimateAmountInPool() {
    try {
      const url = '/api/orders/estimate_amount_in_pool';
      const { data } = await this._api.get<{ data: { total_pool: number } }>(
        url
      );

      return data.total_pool;
    } catch (error) {
      console.warn(error);
    }
    return 0;
  }

  async getPhaseBannerContent(
    wallet: string,
    outSymbol: string,
    phase: Phase = Phase.Sunrise
  ) {
    const { setLoading, setContent } = phaseBannerStore.getState();
    setLoading(true);
    try {
      const url = `/api/user_journey/progress_tasks_by_journey/${wallet}/${phase}/${outSymbol}`;

      const { message, images } = await this._api.get<{
        message: string;
        images: string[];
      }>(url);

      setContent(message, images);

      return { message, images };
    } catch (error) {
      logInGroup('PhaseBanner', error);
    } finally {
      setLoading(false);
    }
    return undefined;
  }

  async mintTonNFT(wallet: string) {
    const url = `/api/user_journey/claim_ton_nft/${wallet}`;

    const data = await this._api.get<{
      wallet_address: string;
      is_claimed: true;
    }>(url);

    if (!data.is_claimed) {
      throw new Error('NFT not claimed');
    }

    return data;
  }
}

export const phaseService = new PhaseService();
