import { useTonAddress } from '@tonconnect/ui-react';
import { useCallback, useEffect, useState } from 'react';
import { useAccount as useEVMAccount } from 'wagmi';

import { getSolanaBalance } from '@/solana/utils/getSolanaBalance';
import { useStarknetAccount } from '@/starknet/hooks/account';
import { getTronBalance } from '@/tron/utils/getTronBalance';
import { useWallet as useSolanaWallet } from '@solana/wallet-adapter-react';
import { useWallet as useTronWallet } from '@tronweb3/tronwallet-adapter-react-hooks';
import { useDebounceCallback } from 'usehooks-ts';
import { getEvmBalance } from '../evm/utils';
import { NetworkTypes } from '../providers/web3Provider';
import { getStarknetBalance } from '../starknet/utils/getStarknetBalance';
import { getTonBalance } from '../ton/utils';
import { ICurrency, INetwork } from '../types/apiTypes';
import { getRPCByNetwork } from '../utils/getRpcUrl';
import { getFuelBalance } from '@/fuel/utils/getFuelBalance';
import { useFuelWallet } from '@/fuel/hooks/useFuelWallet';
import { useSuiWallet } from '@/sui/hooks/useSuiWallet';
import { getSuiBalance } from '@/sui/utils/getSuiBalance';
import {
  useAccount as useBitcoinAccount,
  useBalance as useBitcoinBalance,
} from '@gobob/sats-wagmi';
import { SATOSHI_IN_BTC } from '@/bitcoin/constants';

export const useBalance = (token: ICurrency | undefined) => {
  const { address } = useEVMAccount();
  const tonAddress = useTonAddress();
  const { address: starknetAddress } = useStarknetAccount();
  const { publicKey: solanaAddress } = useSolanaWallet();
  const { address: tronAddress } = useTronWallet();
  const { address: fuelAddress, wallet: fuelWallet } = useFuelWallet();
  const { address: bitcoinAddress } = useBitcoinAccount();
  const { refetch: refetchBitcoinBalance } = useBitcoinBalance({
    experimental_prefetchInRender: false,
  });
  const { address: suiAddress, wallet: suiWallet } = useSuiWallet();

  const [balance, setBalance] = useState('0');
  const [loading, setLoading] = useState(true);

  const fetchBalanceCallback = useCallback(
    async (
      network?: INetwork,
      contractAddress?: string,
      contractDecimals?: number,
      showLoading = true,
      setState = true
    ) => {
      try {
        if (showLoading) {
          setLoading(true);
        }

        const rpcItem = await getRPCByNetwork(network);

        const isEVM =
          network?.network_type === NetworkTypes.EVM ||
          network?.network_type === NetworkTypes.ZK_SYNC_ERA;
        const isStarknet = network?.network_type === NetworkTypes.STARKNET;
        const isTon = network?.network_type === NetworkTypes.TON;
        const isSol = network?.network_type === NetworkTypes.SOLANA;
        const isTron = network?.network_type === NetworkTypes.TRON;
        const isFuel = network?.network_type === NetworkTypes.FUEL;
        const isBitcoin = network?.network_type === NetworkTypes.BITCOIN;
        const isSui = network?.network_type === NetworkTypes.SUI;

        if (address && isEVM) {
          const balance = await getEvmBalance(
            address,
            rpcItem.rpc,
            contractAddress,
            contractDecimals
          );
          if (setState) {
            setBalance(balance);
          }
          return balance;
        } else if (starknetAddress && isStarknet) {
          const balance = await getStarknetBalance(
            starknetAddress,
            rpcItem.rpc,
            true,
            contractAddress,
            contractDecimals
          );
          if (setState) {
            setBalance(balance);
          }
          return balance;
        } else if (tonAddress && isTon) {
          const balance = await getTonBalance(
            tonAddress,
            rpcItem.rpc,
            contractAddress,
            contractDecimals
          );
          if (setState) {
            setBalance(balance);
          }
          return balance;
        } else if (solanaAddress && isSol) {
          const balance = await getSolanaBalance(
            solanaAddress.toBase58(),
            rpcItem.rpc,
            contractAddress,
            contractDecimals
          );
          if (setState) {
            setBalance(balance);
          }
          return balance;
        } else if (isTron && tronAddress) {
          const balance = await getTronBalance(
            tronAddress,
            rpcItem.rpc,
            contractAddress,
            contractDecimals
          );
          if (setState) {
            setBalance(balance);
          }
          return balance;
        } else if (isFuel && fuelAddress && fuelWallet) {
          const provider = fuelWallet?.provider;
          const balance = await getFuelBalance(
            fuelAddress,
            provider,
            contractAddress,
            contractDecimals
          );
          if (setState) {
            setBalance(balance);
          }
          return balance;
        } else if (isBitcoin && bitcoinAddress) {
          const { data: balance } = await refetchBitcoinBalance();
          const formattedBalance = balance?.total
            ? +balance.total.toString() / SATOSHI_IN_BTC
            : '0';
          if (setState) {
            setBalance(formattedBalance.toString());
          }
          return formattedBalance.toString();
        } else if (isSui && suiAddress && suiWallet) {
          const balance = await getSuiBalance(
            suiAddress,
            suiWallet,
            contractAddress,
            contractDecimals
          );
          if (setState) {
            setBalance(balance);
          }
          return balance;
        }

        return '0';
      } catch (error) {
        console.error(error);
        if (error instanceof Error) {
          throw new Error(error.message);
        }
        throw new Error('unhandled error');
      } finally {
        if (showLoading) {
          setLoading(false);
        }
      }
    },
    [
      address,
      starknetAddress,
      tonAddress,
      solanaAddress,
      tronAddress,
      fuelWallet,
      fuelAddress,
      bitcoinAddress,
      refetchBitcoinBalance,
      suiAddress,
    ]
  );

  const fetchBalance = useDebounceCallback(fetchBalanceCallback, 500);

  useEffect(() => {
    if (token && !!fetchBalance) {
      fetchBalance(
        token?.contract?.network,
        token?.contract?.address,
        token?.decimals
      );
    } else {
      setBalance('0');
    }
  }, [token, fetchBalance]);

  return { balance, loading, fetchBalanceCallback };
};
