import React from 'react';

interface ProgressBarProps {
  className?: string;
  percentage: number; // The percentage of the bar to fill (0-100)
  height?: string; // Height of the progress bar, e.g., '20px'
  color?: string; // Fill color of the progress bar
  backgroundColor?: string; // Background color of the container
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  className,
  percentage,
  height = '20px',
  color = '#4caf50',
  backgroundColor = 'rgba(255, 255, 255, 0.1)',
}) => {
  return (
    <div
      className={className}
      style={{
        width: '100%',
        backgroundColor,
        borderRadius: '36px',
        overflow: 'hidden',
        height,
      }}
    >
      <div
        style={{
          width: `${percentage}%`,
          background: color,
          borderRadius: '36px',
          height: '100%',
          transition: 'width 0.5s ease',
        }}
      ></div>
    </div>
  );
};

export default ProgressBar;
