import { PhaseTask } from '@/types/phases';
import { LeaderboardItem, RetroPointsUser } from '@/types/retroPoints';
import { create } from 'zustand';

export interface RetroPointsStore {
  /**
   * Indicates whether general data is loading
   */
  isGeneralLoading: boolean;
  setIsGeneralLoading: (isGeneralLoading: boolean) => void;

  /**
   * Indicates whether the user is binding an EVM wallet
   */
  isBindingEVMWallet: boolean;
  setIsBindingWallet: (isBinding: boolean) => void;

  /**
   * User data
   */
  user?: RetroPointsUser;
  setUser: (user?: RetroPointsUser) => void;

  /**
   * Indicates whether the welcome dialog is open
   */
  isWelcomeDialogOpen: boolean;
  setWelcomeDialogOpen: (isOpen: boolean) => void;

  /**
   * Points earned by the user before the welcome dialog
   */
  welcomePoints: number;
  setWelcomePoints: (points: number) => void;

  /**
   * Key for the referral links
   */
  referralKey: string;
  setReferralKey: (key: string) => void;

  /**
   * Total amount of referees
   */
  amountOfReferees: number;
  setAmountOfReferees: (amount: number) => void;

  /**
   * Total amount of completed bridges by referees
   */
  amountOfRefereesBridges: number;
  setAmountOfRefereesBridges: (amount: number) => void;

  /**
   * Points earned by referees
   */
  refereesPoints: number;
  setRefereesPoints: (points: number) => void;

  /**
   * Weekly leaderboard
   */
  weeklyLeaderboard: LeaderboardItem[];
  setWeeklyLeaderboard: (users: LeaderboardItem[]) => void;

  /**
   * Daily tasks
   */
  dailyTasks: PhaseTask[];
  setDailyTasks: (tasks: PhaseTask[]) => void;
}

export const retroPointsStore = create<RetroPointsStore>(set => ({
  isGeneralLoading: false,
  setIsGeneralLoading: isGeneralLoading => set({ isGeneralLoading }),

  isBindingEVMWallet: false,
  setIsBindingWallet: isBinding => set({ isBindingEVMWallet: isBinding }),

  user: undefined,
  setUser: user => set({ user }),

  isWelcomeDialogOpen: false,
  setWelcomeDialogOpen: isOpen => set({ isWelcomeDialogOpen: isOpen }),

  welcomePoints: 0,
  setWelcomePoints: points => set({ welcomePoints: points }),

  referralKey: '',
  setReferralKey: referralKey => set({ referralKey }),

  amountOfReferees: 0,
  setAmountOfReferees: amount => set({ amountOfReferees: amount }),

  amountOfRefereesBridges: 0,
  setAmountOfRefereesBridges: amount =>
    set({ amountOfRefereesBridges: amount }),

  refereesPoints: 0,
  setRefereesPoints: points => set({ refereesPoints: points }),

  weeklyLeaderboard: [],
  setWeeklyLeaderboard: users => set({ weeklyLeaderboard: users }),

  dailyTasks: [],
  setDailyTasks: tasks => set({ dailyTasks: tasks }),
}));

export const useRetroPointsStore = retroPointsStore;
