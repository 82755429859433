import { DailyTask } from '../TaskComponents/DailyTask';
import { RetroBridgerPointsIcon } from '@/Assets/Icons/RetroBrdigePoints';
import ProgressBar from '@/components/ProgressBar/ProgressBar';
import { useRetroPointsStore } from '@/stores/retroPoints.store';
import { useEffect, useMemo } from 'react';
import { retroPointsService } from '@/services/retroPoints';
import { useAccount } from 'wagmi';

export function TasksTab() {
  const { isConnected } = useAccount();
  const [tasks, user] = useRetroPointsStore(s => [s.dailyTasks, s.user]);

  const completed = useMemo(() => tasks.filter(t => t.isCompleted), [tasks]);

  useEffect(() => {
    if (isConnected && user) retroPointsService.getDailyTasks();
  }, [isConnected, user]);

  return (
    <>
      <div className="notifications-menu--group">
        {tasks.map(task => (
          <DailyTask task={task} />
        ))}
      </div>
      <div className="notifications-menu--footer">
        <div className="flex-col">
          <div className="notifications-menu--footer--progress-title">
            <span className="notifications-menu--footer--progress-title-left">
              Daily progress
            </span>
            <span className="notifications-menu--footer--progress-title-right">
              {completed.length}
              <span className="text-purple">/{tasks.length} daily tasks</span>
            </span>
          </div>
          <ProgressBar
            className="notifications-menu--footer--progress-bar"
            percentage={(completed.length / tasks.length) * 100}
            color="linear-gradient(90deg, #9e86ff 0%, #e1c9ff 100%)"
            backgroundColor="rgba(255, 255, 255, 0.1)"
            height="4px"
          />
          <div className="notifications-menu--footer--reward">
            Complete all daily tasks and get extra{' '}
            <RetroBridgerPointsIcon width={21} height={14} />
            <span> 300 points</span>
          </div>
        </div>
      </div>
    </>
  );
}
