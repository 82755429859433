import { create } from 'zustand';
import {
  ICurrency,
  ICurrencyWithPairs,
  INetwork,
  ITransactionFee,
} from '../types/apiTypes';

export enum WalletToType {
  CurrentWallet = 'currentWallet',
  InputAddress = 'inputAddress',
}

export interface AppStore {
  /**
   * Whether the advanced options are open
   */
  isAdvancedOpen: boolean;
  setIsAdvancedOpen: (isAdvancedOpen: boolean) => void;

  /**
   * Whether the app or a part of it is loading
   */
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;

  /**
   * "You send" input value
   */
  amountFrom: string;
  setAmountFrom: (amountFrom: string) => void;

  /**
   * "You receive" input value
   */
  amountTo: string;
  setAmountTo: (amountTo: string) => void;

  amountOutAbortController: AbortController | null;
  setAmountOutAbortController: (ctrl: AbortController | null) => void;

  /**
   * Source network
   */
  networkFrom?: INetwork;
  setNetworkFrom: (networkFrom: INetwork) => void;

  /**
   * Destination network
   */
  networkTo?: INetwork;
  setNetworkTo: (networkTo: INetwork) => void;

  /**
   * Array of source currencies to choose from
   */
  sourceCurrencies: ICurrencyWithPairs[];
  setSourceCurrencies: (sourceCurrencies: ICurrencyWithPairs[]) => void;

  /**
   * Array of source currencies to choose from
   */
  destinationCurrencies: ICurrency[];
  setDestinationCurrencies: (destinationCurrencies: ICurrency[]) => void;

  /**
   * Source currency
   */
  currencyFrom?: ICurrencyWithPairs;
  setCurrencyFrom: (currencyFrom: ICurrencyWithPairs) => void;

  /**
   * Destination currency
   */
  currencyTo?: ICurrency;
  setCurrencyTo: (currencyTo: ICurrency) => void;

  /**
   * Min send limit (dynamic based on destination chain limits received from backend)
   */
  minSend: number;
  setMinSend: (minSend: number) => void;

  /**
   * Max send limit (dynamic based on destination chain limits received from backend)
   */
  maxSend: number;
  setMaxSend: (maxSend: number) => void;

  /**
   * Source wallet address
   */
  walletFrom: string;
  setWalletFrom: (walletFrom: string) => void;

  /**
   * Destination wallet address
   */
  walletTo: string;
  setWalletTo: (walletTo: string) => void;

  /**
   * Order deposit receiver address
   */
  receiver: string;
  setReceiver: (address: string) => void;

  /**
   * Transaction fee data
   */
  transactionFee: ITransactionFee | null;
  setTransactionFee: (value: ITransactionFee | null) => void;

  /**
   * Type of the destination wallet address
   * - CurrentWallet: the address of the connected wallet
   * - InputAddress: a manually inputted address
   */
  walletToType: WalletToType;
  setWalletToType: (walletToType: WalletToType) => void;

  /**
   * Validation error message
   */
  error: string | null;
  setError: (error: string | null) => void;

  /**
   * Whether the transfer button is disabled
   */
  isDisabled: boolean;
  setIsDisabled: (isDisabled: boolean) => void;

  /**
   * Whether the transfer is cancelled
   */
  isCancelled: boolean;
  setIsCancelled: (isCancelled: boolean) => void;

  /**
   * Whether the app is waiting for confirmation from the user to proceed with the transfer
   */
  isWaitingForConfirmation: boolean;
  setIsWaitingForConfirmation: (isWaitingForConfirmation: boolean) => void;

  /**
   * Whether the confirm page is open
   */
  isConfirmOpen: boolean;
  setIsConfirmOpen: (isConfirmOpen: boolean) => void;

  /**
   * Clear the store data
   */
  clearStore: () => void;
}

const CLEARED_STORE_VALUES = {
  isLoading: false,
  amountFrom: '',
  amountTo: '0.00',
  networkFrom: undefined,
  networkTo: undefined,
  sourceCurrencies: [],
  destinationCurrencies: [],
  currencyPairs: [],
  currencyFrom: undefined,
  currencyTo: undefined,
  walletTo: '',
  walletToType: WalletToType.CurrentWallet,
  error: null,
  isDisabled: false,
  isCancelled: false,
  isWaitingForConfirmation: false,
  feeData: null,
  amountOutAbortController: null,
};

export const appStore = create<AppStore>(set => ({
  isAdvancedOpen: false,
  setIsAdvancedOpen: isAdvancedOpen => set({ isAdvancedOpen }),

  isLoading: false,
  setIsLoading: isLoading => set({ isLoading }),

  amountFrom: '',
  setAmountFrom: amountFrom => set({ amountFrom }),

  amountTo: '0.00',
  setAmountTo: amountTo => set({ amountTo }),

  amountOutAbortController: null,
  setAmountOutAbortController: amountOutAbortController =>
    set({ amountOutAbortController }),

  networkFrom: undefined,
  setNetworkFrom: networkFrom => set({ networkFrom }),

  networkTo: undefined,
  setNetworkTo: networkTo => set({ networkTo }),

  sourceCurrencies: [],
  setSourceCurrencies: sourceCurrencies => set({ sourceCurrencies }),

  destinationCurrencies: [],
  setDestinationCurrencies: destinationCurrencies =>
    set({ destinationCurrencies }),

  currencyFrom: undefined,
  setCurrencyFrom: currencyFrom => set({ currencyFrom }),

  currencyTo: undefined,
  setCurrencyTo: currencyTo => set({ currencyTo }),

  minSend: 0,
  setMinSend: minSend => set({ minSend }),

  maxSend: 0,
  setMaxSend: maxSend => set({ maxSend }),

  walletFrom: '',
  setWalletFrom: walletFrom => set({ walletFrom }),

  walletTo: '',
  setWalletTo: walletTo => set({ walletTo }),

  receiver: '',
  setReceiver: (receiver: string) => set({ receiver }),

  transactionFee: null,
  setTransactionFee: (transactionFee: ITransactionFee | null) =>
    set({ transactionFee }),

  walletToType: WalletToType.CurrentWallet,
  setWalletToType: walletToType => set({ walletToType }),

  error: null,
  setError: error => set({ error }),

  isDisabled: false,
  setIsDisabled: isDisabled => set({ isDisabled }),

  isCancelled: false,
  setIsCancelled: isCancelled => set({ isCancelled }),

  isWaitingForConfirmation: false,
  setIsWaitingForConfirmation: isWaitingForConfirmation =>
    set({ isWaitingForConfirmation }),

  isConfirmOpen: false,
  setIsConfirmOpen: isConfirmOpen => set({ isConfirmOpen }),

  clearStore: () => set(CLEARED_STORE_VALUES),
}));

export const useAppStore = appStore;
