import { motion } from 'framer-motion';
import { useMemo } from 'react';
import { useWindowSize } from 'usehooks-ts';

interface LoadingCirclesProps {
  status?: 'waiting' | 'progress' | 'filled';
  isPaused?: boolean;
  onFinish?: () => void;
  duration?: number;
}

const circleSize = 6;
const initialColor = '#FFFFFF1A';
const fillColor = '#B2A6FF';

export function LoadingCircles({
  status = 'waiting',
  isPaused,
  onFinish,
  duration = 5,
}: LoadingCirclesProps) {
  const { width } = useWindowSize();

  const totalCircles = useMemo(() => {
    return width <= 480 ? 9 : 11;
  }, [width]);

  return (
    <svg width={(totalCircles * 2 - 1) * circleSize} height={circleSize}>
      {Array.from({ length: totalCircles }).map((_, index) => (
        <motion.circle
          key={index}
          cx={(index * 2 + 0.5) * circleSize}
          cy={circleSize / 2}
          r={circleSize / 2}
          initial={{ fill: initialColor }}
          animate={{
            fill:
              (status === 'progress' || status === 'filled') && !isPaused
                ? fillColor
                : initialColor,
          }}
          onAnimationComplete={() => {
            if (
              index === totalCircles - 1 &&
              (status === 'progress' || status === 'filled') &&
              !isPaused
            ) {
              onFinish?.();
            }
          }}
          transition={{
            duration: duration / totalCircles,
            delay: index * (duration / totalCircles),
          }}
        />
      ))}
    </svg>
  );
}
