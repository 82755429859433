import { useMemo } from 'react';
import {
  useAccount,
  useConnect,
  useDisconnect,
  useSatsWagmi,
  useSendTransaction,
} from '@gobob/sats-wagmi';

import { getBitcoinWalletIcon } from '../utils/getBitcoinWalletIcon';

export const DISABLED_CONNECTORS = ['bitget', 'binancew3w', 'metamask_snap'];

export const useBitcoinWallet = () => {
  const { address } = useAccount();
  const { connector: wallet } = useSatsWagmi();
  const { disconnectAsync } = useDisconnect();
  const { connectors, connectAsync } = useConnect();
  const { sendTransactionAsync } = useSendTransaction();

  const wallets = connectors
    .filter(c => !DISABLED_CONNECTORS.includes(c.id))
    .map(c => ({
      name: c.name,
      icon: getBitcoinWalletIcon(c.id),
      connect: () => connectAsync({ connector: c }),
    }));

  const icon = useMemo(
    () => getBitcoinWalletIcon(wallet?.name),
    [wallet?.name]
  );

  const signMessage = async (message: string) => {
    if (!wallet) {
      throw new Error('Current wallet is not authorized for this connection!');
    }

    const signedMessage = await wallet.signMessage(message);
    return signedMessage;
  };

  const sendTransaction = async (to: string, value: bigint) => {
    try {
      const res = await sendTransactionAsync({ to, value });
      return res;
    } catch (error) {
      if (wallet && wallet.id === 'xverse') return true;
      else throw error;
    }
  };

  return {
    wallet,
    address: wallet ? address : undefined,
    signMessage,
    sendTransaction,
    disconnect: disconnectAsync,
    icon,
    wallets,
  };
};
