import { useConnectedIcon } from '@/hooks/useConnectedIcon';
import { useSolanaConnectedIcon } from '@/hooks/useSolanaConnectedIcon';
import { useTonConnectedIcon } from '@/hooks/useTonConnectedIcon';
import { useStarknetConnectedIcon } from '@/starknet/hooks/useConnectedIcon';
import { useConnectedTronIcon } from '@/tron/hooks/useConnectedTronIcon';
import { useWallet as useSolanaWallet } from '@solana/wallet-adapter-react';
import { useTonAddress, useTonConnectUI } from '@tonconnect/ui-react';
import { useWallet as useTronWallet } from '@tronweb3/tronwallet-adapter-react-hooks';
import { useDisconnect, useAccount as useEVMAccount } from 'wagmi';
import './styles.css';
import {
  PopoverClose,
  PopoverContent,
  PopoverPortal,
} from '@radix-ui/react-popover';
import { PowerIcon } from '@/Assets/Icons/Power';
import {
  NetworkTypes,
  useWalletConnectInteractor,
} from '@/providers/web3Provider';
import starknet from '@/Assets/images/wallets/starknet.png';
import ton from '@/Assets/images/wallets/ton.png';
import solana from '@/Assets/images/wallets/solana.png';
import tron from '@/Assets/images/wallets/tron.png';
import bitcoin from '@/Assets/images/wallets/bitcoin.png';
import sui from '@/Assets/images/wallets/sui.png';
import { CopyIcon } from '@/Assets/Icons/Copy';
import { TickIcon } from '@/Assets/Icons/Tick';
import { useCopyToClipboard } from '@/hooks/useCopyToClipboard';
import { ConnectWalletModalWithDisabled } from '@/components/ConnectWalletModalWithDisabled';
import { useState } from 'react';
import { useStarknetAccount } from '@/starknet/hooks/account';
import { useWindowSize } from 'usehooks-ts';
import metamask from '@/Assets/images/wallets/metaMask.png';
import { formatAddress } from '@/utils';
import { useBitcoinWallet } from '@/bitcoin/hooks/useBitcoinWallet';
import { useFuelWallet } from '@/fuel/hooks/useFuelWallet';
import { useConnectedFuelIcon } from '@/fuel/hooks/useConnectedFuelIcon';
import { useSuiWallet } from '@/sui/hooks/useSuiWallet';

interface ConnectedWalletPopoverMenuProps {
  variant: 'evm' | 'non-evm';
}

export function ConnectedWalletPopoverMenu({
  variant,
}: ConnectedWalletPopoverMenuProps) {
  const [isConnectModalOpen, setIsConnectModalOpen] = useState(false);
  const [selectedNetworkType, setSelectedNetworkType] = useState<
    NetworkTypes | undefined
  >();

  const EVMConnectedIcon = useConnectedIcon();
  const { address: EVMAddress } = useEVMAccount();
  const { address: starknetAddress } = useStarknetAccount();
  const starknetIcon = useStarknetConnectedIcon();
  const { publicKey: solanaAddress, disconnect: disconnectSolana } =
    useSolanaWallet();
  const solanaIcon = useSolanaConnectedIcon();
  const { address: tronAddress } = useTronWallet();

  const tronIcon = useConnectedTronIcon();
  const tonAddress = useTonAddress();

  const { address: fuelAddress, disconnect: fuelDisconnect } = useFuelWallet();
  const fuelIcon = useConnectedFuelIcon();

  const tonIcon = useTonConnectedIcon();

  const {
    address: bitcoinAddress,
    icon: bitcoinIcon,
    disconnect: bitcoinDisconnect,
  } = useBitcoinWallet();

  const {
    address: suiAddress,
    icon: suiIcon,
    disconnect: disconnectSuiWallet,
  } = useSuiWallet();

  const [copiedText, copy] = useCopyToClipboard(3000);
  const { width } = useWindowSize();

  // Disconnect functions
  const { handleDeactivate } = useWalletConnectInteractor();
  const { disconnect } = useDisconnect();
  const [tonConnectUI] = useTonConnectUI();
  const { disconnect: tronDisconnect } = useTronWallet();

  const disconnectEVM = async () => {
    if (EVMAddress) {
      disconnect();
    }
  };

  const disconnectStarknet = async () => {
    if (starknetAddress) {
      handleDeactivate(NetworkTypes.STARKNET);
    }
  };

  const disconnectTon = async () => {
    if (tonAddress) {
      await tonConnectUI.disconnect();
    }
  };

  const disconnectTron = async () => {
    if (tronAddress) {
      await tronDisconnect();
    }
  };

  const disconnectBitcoin = async () => {
    if (bitcoinAddress) await bitcoinDisconnect();
  };

  const disconnectFuel = async () => {
    if (fuelAddress) await fuelDisconnect();
  };

  const disconnectSui = async () => {
    if (suiAddress) {
      disconnectSuiWallet();
    }
  };

  const handleOpenConnectModal = (networkType: NetworkTypes) => {
    setSelectedNetworkType(networkType);
    setIsConnectModalOpen(true);
  };

  return (
    <>
      <PopoverPortal>
        <PopoverContent
          sideOffset={width <= 768 ? 9 : 24}
          align="end"
          className="explorer-wallet-popover--content"
        >
          {EVMAddress && variant === 'evm' && (
            <div className="wallet-popover--menu-item">
              <div className="wallet-popover--menu-item--icon">
                <img src={EVMConnectedIcon} width={18} height={18} alt="" />
              </div>
              <div>
                <div className="wallet-popover--menu-item--label">
                  EVM wallet
                </div>
                <div className="wallet-popover--menu-item--address">
                  {formatAddress(EVMAddress, 4, 3)}
                </div>
              </div>
              <div className="d-flex ms-auto gap-2">
                <button
                  className="wallet-popover--menu-item--action"
                  onClick={() => copy(EVMAddress)}
                  disabled={copiedText === EVMAddress}
                >
                  {copiedText === EVMAddress ? (
                    <TickIcon width={14} height={14} color="#fff" />
                  ) : (
                    <CopyIcon width={14} height={14} />
                  )}
                </button>
                <PopoverClose
                  className="wallet-popover--menu-item--action"
                  onClick={disconnectEVM}
                >
                  <PowerIcon />
                </PopoverClose>
              </div>
            </div>
          )}
          {starknetAddress && variant === 'non-evm' && (
            <div className="wallet-popover--menu-item">
              <div className="wallet-popover--menu-item--icon">
                <img src={starknetIcon} alt="" />
              </div>
              <div>
                <div className="wallet-popover--menu-item--label">
                  Starknet wallet
                </div>
                <div className="wallet-popover--menu-item--address">
                  {formatAddress(starknetAddress, 6, 4)}
                </div>
              </div>
              <div className="d-flex ms-auto gap-2">
                <button
                  className="wallet-popover--menu-item--action"
                  onClick={() => copy(starknetAddress)}
                  disabled={copiedText === starknetAddress}
                >
                  {copiedText === starknetAddress ? (
                    <TickIcon width={14} height={14} color="#fff" />
                  ) : (
                    <CopyIcon width={14} height={14} />
                  )}
                </button>
                <PopoverClose
                  className="wallet-popover--menu-item--action"
                  onClick={disconnectStarknet}
                >
                  <PowerIcon />
                </PopoverClose>
              </div>
            </div>
          )}
          {tonAddress && variant === 'non-evm' && (
            <div className="wallet-popover--menu-item">
              <div className="wallet-popover--menu-item--icon">
                <img src={tonIcon} alt="" />
              </div>
              <div>
                <div className="wallet-popover--menu-item--label">
                  TON wallet
                </div>
                <div className="wallet-popover--menu-item--address">
                  {formatAddress(tonAddress, 6, 4)}
                </div>
              </div>
              <div className="d-flex ms-auto gap-2">
                <button
                  className="wallet-popover--menu-item--action"
                  onClick={() => copy(tonAddress)}
                  disabled={copiedText === tonAddress}
                >
                  {copiedText === tonAddress ? (
                    <TickIcon width={14} height={14} color="#fff" />
                  ) : (
                    <CopyIcon width={14} height={14} />
                  )}
                </button>
                <PopoverClose
                  className="wallet-popover--menu-item--action"
                  onClick={disconnectTon}
                >
                  <PowerIcon />
                </PopoverClose>
              </div>
            </div>
          )}
          {solanaAddress && variant === 'non-evm' && (
            <div className="wallet-popover--menu-item">
              <div className="wallet-popover--menu-item--icon">
                <img src={solanaIcon} alt="" />
              </div>
              <div>
                <div className="wallet-popover--menu-item--label">
                  Solana wallet
                </div>
                <div className="wallet-popover--menu-item--address">
                  {formatAddress(solanaAddress.toBase58(), 6, 4)}
                </div>
              </div>
              <div className="d-flex ms-auto gap-2.5">
                <button
                  className="wallet-popover--menu-item--action"
                  onClick={() => copy(solanaAddress.toBase58())}
                  disabled={copiedText === solanaAddress.toBase58()}
                >
                  {copiedText === solanaAddress.toBase58() ? (
                    <TickIcon width={14} height={14} color="#fff" />
                  ) : (
                    <CopyIcon width={14} height={14} />
                  )}
                </button>
                <PopoverClose
                  className="wallet-popover--menu-item--action"
                  onClick={disconnectSolana}
                >
                  <PowerIcon />
                </PopoverClose>
              </div>
            </div>
          )}
          {fuelAddress && variant === 'non-evm' && (
            <div className="wallet-popover--menu-item">
              <div className="wallet-popover--menu-item--icon">
                <img src={fuelIcon} alt="" />
              </div>
              <div>
                <div className="wallet-popover--menu-item--label">
                  Fuel wallet
                </div>
                <div className="wallet-popover--menu-item--address">
                  {formatAddress(fuelAddress, 6, 4)}
                </div>
              </div>
              <div className="d-flex ms-auto gap-2.5">
                <button
                  className="wallet-popover--menu-item--action"
                  onClick={() => copy(fuelAddress)}
                  disabled={copiedText === fuelAddress}
                >
                  {copiedText === fuelAddress ? (
                    <TickIcon width={14} height={14} color="#fff" />
                  ) : (
                    <CopyIcon width={14} height={14} />
                  )}
                </button>
                <PopoverClose
                  className="wallet-popover--menu-item--action"
                  onClick={disconnectFuel}
                >
                  <PowerIcon />
                </PopoverClose>
              </div>
            </div>
          )}
          {tronAddress && variant === 'non-evm' && (
            <div className="wallet-popover--menu-item">
              <div className="wallet-popover--menu-item--icon">
                <img src={tronIcon} alt="" />
              </div>
              <div>
                <div className="wallet-popover--menu-item--label">
                  Tron wallet
                </div>
                <div className="wallet-popover--menu-item--address">
                  {formatAddress(tronAddress, 6, 4)}
                </div>
              </div>
              <div className="d-flex ms-auto gap-2.5">
                <button
                  className="wallet-popover--menu-item--action"
                  onClick={() => copy(tronAddress)}
                  disabled={copiedText === tronAddress}
                >
                  {copiedText === tronAddress ? (
                    <TickIcon width={14} height={14} color="#fff" />
                  ) : (
                    <CopyIcon width={14} height={14} />
                  )}
                </button>
                <PopoverClose
                  className="wallet-popover--menu-item--action"
                  onClick={disconnectTron}
                >
                  <PowerIcon />
                </PopoverClose>
              </div>
            </div>
          )}
          {bitcoinAddress && variant === 'non-evm' && (
            <div className="wallet-popover--menu-item">
              <div className="wallet-popover--menu-item--icon">
                <img src={bitcoinIcon} alt="" />
              </div>
              <div>
                <div className="wallet-popover--menu-item--label">
                  Bitcoin wallet
                </div>
                <div className="wallet-popover--menu-item--address">
                  {formatAddress(bitcoinAddress, 6, 4)}
                </div>
              </div>
              <div className="d-flex ms-auto gap-2.5">
                <button
                  className="wallet-popover--menu-item--action"
                  onClick={() => copy(bitcoinAddress)}
                  disabled={copiedText === bitcoinAddress}
                >
                  {copiedText === bitcoinAddress ? (
                    <TickIcon width={14} height={14} color="#fff" />
                  ) : (
                    <CopyIcon width={14} height={14} />
                  )}
                </button>
                <PopoverClose
                  className="wallet-popover--menu-item--action"
                  onClick={disconnectBitcoin}
                >
                  <PowerIcon />
                </PopoverClose>
              </div>
            </div>
          )}
          {suiAddress && variant === 'non-evm' && (
            <div className="wallet-popover--menu-item">
              <div className="wallet-popover--menu-item--icon">
                <img src={suiIcon} alt="" />
              </div>
              <div>
                <div className="wallet-popover--menu-item--label">
                  Sui wallet
                </div>
                <div className="wallet-popover--menu-item--address">
                  {formatAddress(suiAddress, 6, 4)}
                </div>
              </div>
              <div className="d-flex ms-auto gap-2.5">
                <button
                  className="wallet-popover--menu-item--action"
                  onClick={() => copy(suiAddress)}
                  disabled={copiedText === suiAddress}
                >
                  {copiedText === suiAddress ? (
                    <TickIcon width={14} height={14} color="#fff" />
                  ) : (
                    <CopyIcon width={14} height={14} />
                  )}
                </button>
                <PopoverClose
                  className="wallet-popover--menu-item--action"
                  onClick={disconnectSui}
                >
                  <PowerIcon />
                </PopoverClose>
              </div>
            </div>
          )}
          {variant === 'non-evm' && (
            <div className="explorer-wallet-popover--divider" />
          )}
          {!EVMAddress && variant === 'non-evm' && (
            <button
              className="explorer-wallet-popover--button"
              onClick={() => handleOpenConnectModal(NetworkTypes.EVM)}
            >
              +
              <div className="wallet-popover--menu-item--icon">
                <img src={metamask} alt="" />
              </div>
              <span className="text-gradient">Add EVM wallet</span>
            </button>
          )}
          {!starknetAddress && variant === 'non-evm' && (
            <button
              className="explorer-wallet-popover--button"
              onClick={() => handleOpenConnectModal(NetworkTypes.STARKNET)}
            >
              +
              <div className="wallet-popover--menu-item--icon">
                <img src={starknet} alt="" />
              </div>
              <span className="text-gradient">Add Starknet wallet</span>
            </button>
          )}
          {!tonAddress && variant === 'non-evm' && (
            <button
              className="explorer-wallet-popover--button"
              onClick={() => handleOpenConnectModal(NetworkTypes.TON)}
            >
              +
              <div className="wallet-popover--menu-item--icon">
                <img src={ton} alt="" />
              </div>
              <span className="text-gradient">Add TON wallet</span>
            </button>
          )}
          {!solanaAddress && variant === 'non-evm' && (
            <button
              className="explorer-wallet-popover--button"
              onClick={() => handleOpenConnectModal(NetworkTypes.SOLANA)}
            >
              +
              <div className="wallet-popover--menu-item--icon">
                <img src={solana} alt="" />
              </div>
              <span className="text-gradient">Add Solana wallet</span>
            </button>
          )}
          {!fuelAddress && variant === 'non-evm' && (
            <button
              className="explorer-wallet-popover--button"
              onClick={() => handleOpenConnectModal(NetworkTypes.FUEL)}
            >
              +
              <div className="wallet-popover--menu-item--icon">
                <img src={fuelIcon} alt="" />
              </div>
              <span className="text-gradient">Add Fuel wallet</span>
            </button>
          )}
          {!tronAddress && variant === 'non-evm' && (
            <button
              className="explorer-wallet-popover--button"
              onClick={() => handleOpenConnectModal(NetworkTypes.TRON)}
            >
              +
              <div className="wallet-popover--menu-item--icon">
                <img src={tron} alt="" />
              </div>
              <span className="text-gradient">Add Tron wallet</span>
            </button>
          )}
          {!bitcoinAddress && variant === 'non-evm' && (
            <button
              className="explorer-wallet-popover--button"
              onClick={() => handleOpenConnectModal(NetworkTypes.BITCOIN)}
            >
              +
              <div className="wallet-popover--menu-item--icon">
                <img src={bitcoin} alt="" />
              </div>
              <span className="text-gradient">Add Bitcoin wallet</span>
            </button>
          )}
          {!suiAddress && variant === 'non-evm' && (
            <button
              className="explorer-wallet-popover--button"
              onClick={() => handleOpenConnectModal(NetworkTypes.SUI)}
            >
              +
              <div className="wallet-popover--menu-item--icon">
                <img src={sui} alt="" />
              </div>
              <span className="text-gradient">Add Sui wallet</span>
            </button>
          )}
        </PopoverContent>
      </PopoverPortal>

      <ConnectWalletModalWithDisabled
        openModal={isConnectModalOpen}
        setOpenModal={setIsConnectModalOpen}
        networkType={selectedNetworkType}
        onlySelectedNetworkType
      />
    </>
  );
}
