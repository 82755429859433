import { isValidSuiAddress } from '@mysten/sui/utils';

export const isValidSui1Address = (address?: string) => {
  if (!address) return false;
  try {
    return isValidSuiAddress(address);
  } catch (e) {
    return false;
  }
};
