import { Loader } from '@/components/Loader';
import { useEagerStarknetConnect } from '@/hooks/useEagerStarknetConnect';
import { NetworkTypes } from '@/providers/web3Provider';
import { retroPointsService } from '@/services/retroPoints';
import { useStarknetAccount } from '@/starknet/hooks/account';
import {
  retroPointsStore,
  useRetroPointsStore,
} from '@/stores/retroPoints.store';
import { useWallet as useSolanaWallet } from '@solana/wallet-adapter-react';
import { useTonAddress } from '@tonconnect/ui-react';
import { useWallet as useTronWallet } from '@tronweb3/tronwallet-adapter-react-hooks';
import { Suspense, useEffect, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import { useAccount, useAccountEffect } from 'wagmi';
import { useShallow } from 'zustand/react/shallow';
import Footer from '../../components/Footer';
import { Header } from '../../components/Header';
import { Navbar } from './components/Navbar';
import { Sidebar } from './components/Sidebar';
import { WelcomeDialog } from './components/WelcomeDialog';
import './styles.css';
import { AxiosError } from 'axios';
import { useNotify } from '@/hooks/useToast';
import { useFuelWallet } from '@/fuel/hooks/useFuelWallet';
import { useBitcoinWallet } from '@/bitcoin/hooks/useBitcoinWallet';
import { useSuiWallet } from '@/sui/hooks/useSuiWallet';

export function QuestsLayout() {
  const [
    isGeneralLoading,
    isWelcomeDialogOpen,
    welcomePoints,
    setWelcomeDialogOpen,
    setWelcomePoints,
  ] = useRetroPointsStore(
    useShallow(s => [
      s.isGeneralLoading,
      s.isWelcomeDialogOpen,
      s.welcomePoints,
      s.setWelcomeDialogOpen,
      s.setWelcomePoints,
    ])
  );
  const { address, isConnected } = useAccount();

  useEffect(() => {
    document.body.classList.add('body');
    return () => {
      document.body.classList.remove('body');
    };
  }, []);

  useEagerStarknetConnect();

  useAccountEffect({
    onDisconnect: () => {
      retroPointsStore.setState({
        user: undefined,
        isGeneralLoading: false,
      });
    },
  });

  useEffect(() => {
    if (!address || !isConnected) return;

    const { isBindingEVMWallet } = retroPointsStore.getState();
    if (isBindingEVMWallet) return;

    retroPointsService
      .checkIsWalletAuthorized(address)
      .then(() => retroPointsService.userInfo(true, true))
      .then(res => {
        if (
          !res?.is_welcome_shown &&
          (res?.points ?? 0) > 0 &&
          (res?.total_bridges ?? 0) > 0
        ) {
          setWelcomeDialogOpen(true);
          setWelcomePoints(res?.points ?? 0);
        } else {
          retroPointsService.setWelcomeShowed();
        }
      });
  }, [address, isConnected, setWelcomeDialogOpen, setWelcomePoints]);

  const { address: starknetAddress } = useStarknetAccount();
  const { publicKey: solanaAddress } = useSolanaWallet();
  const tonAddress = useTonAddress();
  const { address: tronAddress } = useTronWallet();
  const { address: fuelAddress } = useFuelWallet();
  const { address: bitcoinAddress } = useBitcoinWallet();
  const { address: suiAddress } = useSuiWallet();

  const isOnce = useRef(false);
  const user = useRetroPointsStore(s => s.user);
  const { notify } = useNotify();

  useEffect(() => {
    const errorNotifier = (error?: unknown) => {
      if (error instanceof AxiosError) {
        return notify({
          type: 'error',
          meassage: error.response?.data.message,
        });
      }
      return notify({
        type: 'error',
        meassage: 'Something went wrong. Please try again later.',
      });
    };

    if (!user || isOnce.current) return;

    const timeout = setTimeout(() => {
      if (!user.starknet_address && starknetAddress) {
        retroPointsService
          .bindWallet(undefined, starknetAddress, NetworkTypes.STARKNET)
          .catch(errorNotifier);
      }
      if (!user.solana_address && solanaAddress) {
        retroPointsService
          .bindWallet(undefined, solanaAddress?.toBase58(), NetworkTypes.SOLANA)
          .catch(errorNotifier);
      }
      if (!user.ton_address && tonAddress) {
        retroPointsService
          .bindWallet(undefined, tonAddress, NetworkTypes.TON)
          .catch(errorNotifier);
      }
      if (!user.tron_address && tronAddress) {
        retroPointsService
          .bindWallet(undefined, tronAddress || undefined, NetworkTypes.TRON)
          .catch(errorNotifier);
      }
      if (!user.fuel_address && fuelAddress) {
        retroPointsService
          .bindWallet(undefined, fuelAddress || undefined, NetworkTypes.FUEL)
          .catch(errorNotifier);
      }
      if (!user.bitcoin_address && bitcoinAddress) {
        retroPointsService
          .bindWallet(
            undefined,
            bitcoinAddress || undefined,
            NetworkTypes.BITCOIN
          )
          .catch(errorNotifier);
      }

      if (!user.sui_address && suiAddress) {
        retroPointsService
          .bindWallet(undefined, suiAddress, NetworkTypes.SUI)
          .catch(errorNotifier);
      }
      isOnce.current = true;
    }, 3000);

    return () => clearTimeout(timeout);
  }, [
    user,
    starknetAddress,
    solanaAddress,
    tonAddress,
    tronAddress,
    fuelAddress,
    bitcoinAddress,
    notify,
  ]);

  useEffect(() => {
    if (isConnected && user) retroPointsService.getDailyTasks();
  }, [isConnected, user]);

  const handleWelcomeDialogOpenChange = async (val: boolean) => {
    setWelcomeDialogOpen(val);
    if (!val) {
      await retroPointsService.setWelcomeShowed();
      await retroPointsService.userInfo(true, true);
    }
  };

  return (
    <>
      {isGeneralLoading && (
        <div className="quests-layout--loader">
          <Loader />
        </div>
      )}
      <Header />
      <div className="quests-layout">
        <Sidebar />
        <div className="d-flex flex-column gap-4">
          <Navbar />
          <div className="h-100">
            <Suspense
              fallback={
                <div className="position-relative d-flex justify-content-center align-items-center h-100">
                  <Loader />
                </div>
              }
            >
              <Outlet />
            </Suspense>
          </div>
        </div>
      </div>
      <div className="quests-layout-footer">
        <Footer />
      </div>
      <WelcomeDialog
        open={isWelcomeDialogOpen}
        onOpenChange={handleWelcomeDialogOpenChange}
        points={welcomePoints}
      />
    </>
  );
}
