import { AbstractAddress, BigNumberish } from 'fuels';
import {
  useWallet,
  useAccount,
  useDisconnect,
  useSendTransaction,
} from '@fuels/react';

export const useFuelWallet = () => {
  const { wallet } = useWallet();
  const { account } = useAccount();
  const { disconnectAsync } = useDisconnect();
  const { sendTransactionAsync } = useSendTransaction();

  const signMessage = async (message: string) => {
    if (!wallet) {
      throw new Error('Current wallet is not authorized for this connection!');
    }
    const signedMessage = await wallet.signMessage(message);
    return signedMessage;
  };

  const sendTransaction = async (
    destination: string | AbstractAddress,
    amount: BigNumberish,
    assetId?: string
  ) => {
    if (!wallet) {
      throw new Error('Current wallet is not authorized for this connection!');
    }

    // Create a transaction request using wallet helper
    const transactionRequest = await wallet.createTransfer(
      destination,
      amount,
      assetId
    );

    // Broadcast the transaction to the network
    return await sendTransactionAsync({
      address: wallet.address, // The address to sign the transaction (a connected wallet)
      transaction: transactionRequest, // The transaction to send
    });
  };

  return {
    wallet,
    address: wallet ? account : undefined,
    signMessage,
    sendTransaction,
    disconnect: disconnectAsync,
  };
};
