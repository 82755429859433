import { useMemo } from 'react';

import { useAccount as useEVMAccount } from 'wagmi';
import { useTonAddress } from '@tonconnect/ui-react';
import { useStarknetAccount } from '@/starknet/hooks/account';
import { useWallet as useSolanaWallet } from '@solana/wallet-adapter-react';

import { NetworkTypes } from '@/providers/web3Provider';
import { useAppStore, WalletToType } from '@/stores/app.store';
import { useWallet as useTronWallet } from '@tronweb3/tronwallet-adapter-react-hooks';
import { useFuelWallet } from '@/fuel/hooks/useFuelWallet';
import { useAccount as useBitcoinAccount } from '@gobob/sats-wagmi';
import { useSuiWallet } from '@/sui/hooks/useSuiWallet';

export const useWalletTo = () => {
  const [networkTo, walletTo, walletToType] = useAppStore(s => [
    s.networkTo,
    s.walletTo,
    s.walletToType,
  ]);

  const tonAddress = useTonAddress();
  const { address: evmAddress } = useEVMAccount();
  const { address: starknetAddress } = useStarknetAccount();
  const { publicKey: solanaAddress } = useSolanaWallet();
  const { address: tronAddress } = useTronWallet();
  const { address: fuelAddress } = useFuelWallet();
  const { address: bitcoinAddress } = useBitcoinAccount();
  const { address: suiAddress } = useSuiWallet();

  const isToStarknet = networkTo?.network_type === NetworkTypes.STARKNET;
  const isToTon = networkTo?.network_type === NetworkTypes.TON;
  const isToSolana = networkTo?.network_type === NetworkTypes.SOLANA;
  const isToTron = networkTo?.network_type === NetworkTypes.TRON;
  const isToFuel = networkTo?.network_type === NetworkTypes.FUEL;
  const isToBitcoin = networkTo?.network_type === NetworkTypes.BITCOIN;
  const isToSui = networkTo?.network_type === NetworkTypes.SUI;

  const isToEVM =
    !isToStarknet &&
    !isToTon &&
    !isToSolana &&
    !isToTron &&
    !isToFuel &&
    !isToBitcoin;

  const connectedWalletTo = useMemo(() => {
    switch (networkTo?.network_type) {
      case NetworkTypes.EVM:
        return evmAddress;
      case NetworkTypes.STARKNET:
        return starknetAddress;
      case NetworkTypes.TON:
        return tonAddress;
      case NetworkTypes.SOLANA:
        return solanaAddress?.toBase58();
      case NetworkTypes.TRON:
        return tronAddress || undefined;
      case NetworkTypes.FUEL:
        return fuelAddress || undefined;
      case NetworkTypes.BITCOIN:
        return bitcoinAddress;
      case NetworkTypes.SUI:
        return suiAddress;

      default:
        return evmAddress;
    }
  }, [
    evmAddress,
    starknetAddress,
    tonAddress,
    solanaAddress,
    tronAddress,
    fuelAddress,
    bitcoinAddress,
    suiAddress,
    networkTo?.network_type,
  ]);

  const isToConnected =
    (isToStarknet && !!starknetAddress) ||
    (isToTon && !!tonAddress) ||
    (isToSolana && !!solanaAddress) ||
    (isToTron && !!tronAddress) ||
    (isToFuel && !!fuelAddress) ||
    (isToSui && !!suiAddress) ||
    (isToEVM && !!evmAddress) ||
    (isToBitcoin && !!bitcoinAddress);

  return {
    isToConnected,
    connectedWalletTo,
    walletTo:
      walletToType === WalletToType.CurrentWallet
        ? connectedWalletTo
        : walletTo,
  };
};
