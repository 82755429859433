import { useEffect } from 'react';

import { ApiService } from '@/services/api';
import { ICurrency, ICurrencyWithPairs } from '@/types/apiTypes';

export const useLimits = (
  api: ApiService,
  currencyFrom?: ICurrency | ICurrencyWithPairs,
  currencyTo?: ICurrency | ICurrencyWithPairs
) => {
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (currencyFrom && currencyTo && currencyFrom.id !== currencyTo.id) {
      // Set a timeout before calling the API
      timeoutId = setTimeout(() => {
        api.getLimits(currencyFrom, currencyTo);
      }, 300);
    }

    // Cleanup function to clear the timeout
    return () => clearTimeout(timeoutId);
  }, [currencyFrom, currencyTo, api]);
};
