import { SVGAttributes } from 'react';

export const PhaseIcons = {
  Plus: (props: SVGAttributes<SVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      {...props}
    >
      <path
        d="M6 3.28125V9.84375"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.71875 6.5625H9.28125"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  Minus: (props: SVGAttributes<SVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      {...props}
    >
      <path
        d="M2.5 6.5H9.5"
        stroke="#2F2038"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  Process: (props: SVGAttributes<SVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      {...props}
    >
      <rect width="42" height="42" rx="8" fill="white" fillOpacity="0.1" />
      <g clipPath="url(#clip0_3154_12773)">
        <path
          d="M23.3765 16.4415C24.6174 17.0905 25.5663 18.2212 25.9564 19.5937H25.2187C25.0457 19.5937 24.8868 19.689 24.8053 19.8414C24.7239 19.9938 24.733 20.1787 24.8287 20.3225C24.8838 20.4175 26.5759 23.3317 26.4694 23.1481C26.6433 23.4089 27.0754 23.4089 27.2494 23.1481C27.4281 22.8404 28.9827 20.2169 28.9214 20.3225C29.0171 20.1787 29.0261 19.9938 28.9447 19.8414C28.8633 19.689 28.7043 19.5937 28.5314 19.5937H27.8901C27.4625 17.4875 26.0848 15.739 24.2323 14.7769C24.2632 14.8944 24.2808 15.0159 24.2808 15.1406C24.2817 16.1436 23.4706 16.379 23.3765 16.4415Z"
          fill="white"
        />
        <path
          d="M20.3226 13.0786C20.1788 12.983 19.9939 12.9739 19.8415 13.0553C19.6891 13.1368 19.5939 13.2956 19.5939 13.4687V14.1098C17.0526 14.6258 15.0144 16.5144 14.2737 18.966C14.5242 18.7682 14.8215 18.6567 15.1407 18.6567C15.5609 18.6567 15.9432 18.8424 16.205 19.1477C16.7854 17.6512 18.0341 16.4869 19.5939 16.0436V16.7812C19.5939 16.9543 19.6891 17.1131 19.8415 17.1947C19.994 17.2761 20.1789 17.2669 20.3226 17.1714C20.4176 17.1162 23.3318 15.424 23.1482 15.5306C23.4092 15.3567 23.4092 14.9245 23.1482 14.7506C22.8406 14.572 20.217 13.0173 20.3226 13.0786Z"
          fill="white"
        />
        <path
          d="M13.0553 23.096C13.1368 23.2485 13.2956 23.3437 13.4687 23.3437H14.3773C14.9739 25.0285 16.2007 26.4024 17.7658 27.2183C17.7357 27.1024 17.7192 26.9824 17.7192 26.8593C17.7182 25.8607 18.5229 25.6241 18.6168 25.5619C17.6732 25.0677 16.8999 24.2927 16.4129 23.3437H16.7812C16.9543 23.3437 17.1131 23.2485 17.1947 23.096C17.2761 22.9437 17.2669 22.7588 17.1712 22.615C17.1162 22.52 15.424 19.6058 15.5306 19.7894C15.3567 19.5285 14.9245 19.5285 14.7506 19.7894C14.6271 20.0022 12.9776 22.789 13.0786 22.615C12.983 22.7588 12.9739 22.9437 13.0553 23.096Z"
          fill="white"
        />
        <path
          d="M22.4062 25.2187C22.4062 25.0457 22.311 24.8868 22.1585 24.8053C22.0062 24.7239 21.8213 24.733 21.6775 24.8287C21.5825 24.8838 18.6683 26.5759 18.8519 26.4694C18.591 26.6433 18.591 27.0754 18.8519 27.2494C19.1595 27.428 21.783 28.9827 21.6775 28.9214C21.8213 29.0171 22.0062 29.0261 22.1585 28.9447C22.311 28.8633 22.4062 28.7043 22.4062 28.5314V27.8901C24.5123 27.4625 26.2607 26.0851 27.2229 24.2327C27.1056 24.2637 26.9841 24.2812 26.8593 24.2812C26.0631 24.2812 25.7616 23.7739 25.5424 23.4091C24.8908 24.6343 23.7668 25.5697 22.4062 25.9564V25.2187Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3154_12773">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(13 13)"
          />
        </clipPath>
      </defs>
    </svg>
  ),
  Done: (props: SVGAttributes<SVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      {...props}
    >
      <g filter="url(#filter0_i_3154_12818)">
        <rect
          width="42"
          height="42"
          rx="8"
          fill="url(#paint0_linear_3154_12818)"
        />
      </g>
      <path
        d="M19.3609 26.23C19.2272 26.3644 19.0449 26.4394 18.8555 26.4394C18.6661 26.4394 18.4838 26.3644 18.3501 26.23L14.3141 22.1933C13.8953 21.7745 13.8953 21.0953 14.3141 20.6772L14.8195 20.1717C15.2385 19.7529 15.9169 19.7529 16.3357 20.1717L18.8555 22.6916L25.6642 15.8827C26.0832 15.4639 26.7623 15.4639 27.1805 15.8827L27.6858 16.3882C28.1047 16.8071 28.1047 17.4861 27.6858 17.9043L19.3609 26.23Z"
        fill="white"
        fillOpacity="0.5"
      />
      <defs>
        <filter
          id="filter0_i_3154_12818"
          x="0"
          y="0"
          width="42"
          height="44"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_3154_12818"
          />
        </filter>
        <linearGradient
          id="paint0_linear_3154_12818"
          x1="21"
          y1="0"
          x2="21"
          y2="42"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3F9F71" />
          <stop offset="1" stopColor="#132E3A" />
        </linearGradient>
      </defs>
    </svg>
  ),
  Refresh: (props: SVGAttributes<SVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      {...props}
    >
      <path
        d="M0.75 3V7.5H5.25"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.25 15V10.5H12.75"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3675 6.75C14.9871 5.67508 14.3407 4.71405 13.4884 3.95656C12.6361 3.19907 11.6059 2.66982 10.4938 2.41819C9.38167 2.16656 8.22393 2.20075 7.12861 2.51758C6.03328 2.8344 5.03606 3.42353 4.23 4.23L0.75 7.5M17.25 10.5L13.77 13.77C12.9639 14.5765 11.9667 15.1656 10.8714 15.4824C9.77607 15.7992 8.61833 15.8334 7.50621 15.5818C6.3941 15.3302 5.36385 14.8009 4.5116 14.0434C3.65935 13.2859 3.01288 12.3249 2.6325 11.25"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  Down: (props: SVGAttributes<SVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      {...props}
    >
      <g id="triange down">
        <path
          id="Polygon 2"
          d="M9.43945 12.1395L5.54234 7.63953L13.3366 7.63953L9.43945 12.1395Z"
          fill="white"
        />
      </g>
    </svg>
  ),
  LogOut: (props: SVGAttributes<SVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M10 2H12.6667C13.0203 2 13.3594 2.14048 13.6095 2.39052C13.8595 2.64057 14 2.97971 14 3.33333V12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14H10"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66699 11.3333L10.0003 7.99999L6.66699 4.66666"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 8H2"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  Error: (props: SVGAttributes<SVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      {...props}
    >
      <circle cx="8" cy="8.5" r="8" fill="#F38383" />
      <path
        d="M10.5 6L5.5 11"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.5 6L10.5 11"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  ArrowRight: (props: SVGAttributes<SVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 19 19"
      fill="none"
      {...props}
    >
      <path
        d="M4.25 9.07922H14.75"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 3.82922L14.75 9.07922L9.5 14.3292"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
};
