import { useEffect, useMemo } from 'react';
import { WeeklyTask } from '../TaskComponents/WeeklyTask';
import ProgressBar from '@/components/ProgressBar/ProgressBar';
import { phaseService } from '@/services/phaseApi';
import { useRetroPointsStore } from '@/stores/retroPoints.store';
import { useQuestsAdventuresStore } from '@/pages/quests/QuestsAdventures/store';
import { Adventure } from '@/types/phases';

export function WeeklyTasksTab() {
  const user = useRetroPointsStore(s => s.user);
  const adventures = useQuestsAdventuresStore(s => s.adventures);
  const tasks = useQuestsAdventuresStore(s => s.tasks);

  useEffect(() => {
    if (user?.evm_address)
      phaseService.getAdventuresWithTasks(user?.evm_address);
  }, [user]);

  const weeklyAdventures = useMemo(() => {
    return adventures.filter(a => a.isWeeklyAdventure && a.isActive);
  }, [adventures]);

  const completedAdventures = useMemo(() => {
    return weeklyAdventures.filter((a: Adventure) => {
      const adventureTasks = tasks[a.id] || [];
      return adventureTasks.every(t => t.isCompleted);
    });
  }, [weeklyAdventures, tasks]);

  return (
    <>
      <div className="notifications-menu--group">
        {weeklyAdventures.map(adventure => (
          <WeeklyTask adventure={adventure} />
        ))}
      </div>
      <div className="notifications-menu--footer">
        <div className="flex-col">
          <div className="notifications-menu--footer--progress-title">
            <span className="notifications-menu--footer--progress-title-left">
              Weekly progress
            </span>
            <span className="notifications-menu--footer--progress-title-right">
              <span className="text-white">{completedAdventures.length}</span>
              <span className="text-purple">
                /{weeklyAdventures.length} weekly adventures
              </span>
            </span>
          </div>
          <ProgressBar
            className="notifications-menu--footer--progress-bar"
            percentage={
              (completedAdventures.length / weeklyAdventures.length) * 100
            }
            color="linear-gradient(90deg, #9e86ff 0%, #e1c9ff 100%)"
            backgroundColor="rgba(255, 255, 255, 0.1)"
            height="4px"
          />
          <div className="notifications-menu--footer--reward">
            Complete all adventures and get extra
            <span> x0.25 multiplier</span>
          </div>
        </div>
      </div>
    </>
  );
}
